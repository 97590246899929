
const dataFooter = [
    {
        id: 1,
        title: 'About Us',
        classAction : 'style-1',
        listlink: [
            {
                id: 1,
                text: 'About Us',
                link: '/about'
            },
        ]
    },
    {
        id: 2,
        title: 'Companey',
        classAction : 'style-2',
        listlink: [
            {
                id: 1,
                text: 'Contact',
                link: '/contact'
            },
        ]
    },
    {
        id: 3,
        title: 'Resources',
        classAction : 'style-3',
        listlink: [
            {
                id: 1,
                text: 'FAQ',
                link: '/Faq'
            },
        ]
    },
]

export default dataFooter;