import React, { useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Blog from '../components/blog/blog-v1';
import dataBlog from '../assets/fake-data/data-blog';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'

function Popup(props) {
    return (props.trigger) ? (
        <div className="popup-video">
            <div className="overlay" onClick={() => props.setTrigger(false)}></div>
            <div className='popup-inner'>
                <div className="popup-inner-content">
                    <div className="mfp-iframe-scaler">
                        <ReactPlayer url='https://www.youtube.com/watch?v=i7EMACWuErA'
                            playing={false}
                            controls={false} className="popup-youtube">
                            <span className="icon-play"></span>
                        </ReactPlayer>
                        <button className='close-btn' onClick={() => props.setTrigger(false)}><i className="far fa-times"></i></button>
                        {props.children}
                    </div>

                </div>
            </div>
        </div>
    ) : "";
}


function About(props) {
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "Mission",
            },
            {
                id: 2,
                title: "Vision",
            },
        ]
    )
    const [buttonPopup, setButtonPopup] = useState(false);
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='About Us' />
            <div className="tf-section flat-about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="wrap-img">
                                <div className="media">
                                    <img src={require('../assets/images/box-item/Designer1.jpeg')} alt="Nufti" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="post">
                                <h2 className="title-about text-t">Buildynamics, Building the Future, Together.</h2>
                                <div className="title-text fs-18 fw-4 text-color-3">At Buildynamic, we are revolutionizing the construction and real estate industries by tackling one of the most pressing challenges:</div>
                                <p className="text-1">  project financing. Our innovative approach integrates blockchain technology, automation, and smart contracts to create a more transparent, efficient, and collaborative environment for construction projects. By enabling suppliers to become partners and share in project ownership, we help streamline resources and align the interests of all stakeholders—leading to faster project completion and cost efficiency.
                                    <br />
                                    Our mission is to empower architects, builders, and investors with cutting-edge tools that simplify project management, improve decision-making, and democratize access to real estate investment opportunities. With Buildynamic, the future of construction is collaborative, decentralized, and smarter than ever.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flat-video">

            </div>
            <div className="flat-about2 flat-counter tf-counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <Tabs>
                                    <TabList className="menu-tab tab-title">
                                        {
                                            dataTab.map(data=> (
                                                <Tab key={data.id} >
                                                    <h3 className="inner">{data.title}</h3>
                                                </Tab>
                                            ))
                                        }
                                    </TabList>
                                <TabPanel>
                                    <div className="provenance">
                                            <p>Facilitation and systematic decision-making to increase the number of participants in the housing construction process</p>

                                             </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="provenance">
                                            <p>To become one of the decision-making bases in the field of housing in Finland in order to achieve consulting for government structures on the one hand, as a partner of banks and financial institutions interested in the field of construction and as the first choice of active brands. In the construction industry as a regulator</p>

                                            </div>
                                </TabPanel>
                                <TabPanel>
                                        <div className="provenance">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                                It has survived not only five centuries, but also the leap into electronic typesetting, 
                                                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                                and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        </div>
                                </TabPanel>
                                <TabPanel>
                                        <div className="provenance">
                                            <p>Maecenas pharetra sem ut metus dignissim, ac tincidunt purus fringilla. Integer ultrices enim at enim ultricies pharetra. 
                                                Nam mollis pretium mi, at sagittis neque blandit id. Pellentesque habitant morbi tristique senectus et netus et malesuada 
                                                fames ac turpis egestas. Phasellus tempor commodo velit sit amet porttitor. Integer id lectus ut arcu posuere pharetra id 
                                                eget lorem. Suspendisse a sollicitudin turpis, ut fringilla lorem. Nam tincidunt libero vel gravida porttitor.</p>
                                        </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                        <div className="col-lg-8 col-md-8">
                        <div className="wrap-img">
                                <div className="media">
                                    <img src={require('../assets/images/box-item/vision.jpeg')} alt="Nufti" />
                                </div>
                            </div>
</div>
                    </div>
                </div>
            </div>

            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} className="popup-youtube">
                <span className="icon-play">abc</span>
            </Popup>
            {<Blog classection='tf-section flat-blog home3 page' data={dataBlog} />}

            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default About;