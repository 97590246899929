const menus = [

    {
        id: 1,
        name: 'Auction Market',
        links: '/live-auctions',
    },
    {
        id: 2,
        name: 'Disputes Panel',
        links: '/jury',
    },
    
]

export default menus;