// api/contact.js

import axios from "axios";

//---------------------1 changes for deploy

import { API_URL } from './constants';

export const sendMessage = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/contact`, formData); // Assuming your backend route is '/api/contact-us'
    console.log("sent from front",formData)
    return response.data; // Return response data if needed
  } catch (error) {
    console.log("don't send from front",error)
    throw error; // Throw error to be handled in the component
  }
};
