import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper';


const BuyCoin1 = (props) => {
    const data = props.data;


    return (
        <Fragment>
            <div className="tf-section flat-top-seller flat-auctions dark-style page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <Swiper
                                className='swiper-container carousel-7 show-shadow btn-collection2'
                                modules={[Navigation, Grid]}
                                spaceBetween={30}
                                navigation
                                grid={{
                                    "rows": 1
                                }}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 40,
                                    },

                                }}
                            >
                                {
                                    data.slice(0, 4).map(item => (
                                        <SwiperSlide key={item.id}>
                                            {
                                                <div className="wrap-seller center">

                                                    <div className="content">
                                                        <h3>{item.title}</h3>
                                                        <div className="price flex">
                                                            <img src={item.icon2} alt="" />
                                                            <div className="title-price fs-16">{item.price}</div>
                                                        </div>
                                                        <div className="price flex">
                                                            <input type="text" placeholder="Promo Code" name="text" style={{ marginLeft: "3em", marginRight: "3em" }} />
                                                        </div>
                                                        <div className="button">
                                                            <Link to="/dashboard-client" className="sc-button btn-4"><span>Buy</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </SwiperSlide>

                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};


export default BuyCoin1;
