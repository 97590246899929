import React , {useState} from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";

import img1 from '../assets/images/icon/check.png';
import img2 from '../assets/images/blog/blog-detail-4.png';

import img3 from '../assets/images/avatar/avt-01.png';
import img4 from '../assets/images/avatar/avt-02.png';
import img5 from '../assets/images/avatar/avt-03.png';

function JuryDetails(props) {

    const [dataRecent] = useState(
        [
            {
                img: img1,
                title: 'Art Dsign',
            },
            {
                img: img1,
                title: 'UX/UI Design',
            },
            {
                img: img1,
                title: 'Web Design',
            }
        ]
    )
    const [dataServices] = useState(
        [
            {
                id: 1,
                text: 'UX Strategy',
                link : '/blog',
            },
            {
                id: 2,
                text: 'Information Architecture',
                link : '/blog',
            },
            {
                id: 3,
                text: 'Prototyping',
                link : '/blog',
            },
            {
                id: 4,
                text: 'Wireframing',
                link : '/blog',
            },
            {
                id: 5,
                text: 'UI Design',
                link : '/blog',
            },
            {
                id: 6,
                text: 'Figma Design',
                link : '/blog',
            }
        ]
    )
    

    const [dataComment] = useState(
        [
            {
                img: img2,
                name: ' Monsur Rahman Lito',
                content: 'Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis. Curabitur ultrices justo ac leo facilisis tincidunt. Maecenas et dui eget nisl ornare scelerisque.',
            },
            {
                img: img2,
                name: ' Monsur Rahman Lito',
                content: 'Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis. Curabitur ultrices justo ac leo facilisis tincidunt. Maecenas et dui eget nisl ornare scelerisque.',
            },
        ]
    )

    const [dataCard] = useState(
        [
            {
                id: 1,
                img: img3,
                title: 'Axtronic Electronics VS-10',
                author: ' Themesflat',
                date: '04/10/2022',
            },
            {
                id: 2,
                img: img4,
                title: 'Axtronic Electronics VS-10',
                author: ' Themesflat',
                date: '04/10/2022',
            },
            {
                id: 3,
                img: img5,
                title: 'Axtronic Electronics VS-10',
                author: ' Themesflat',
                date: '04/10/2022',
            },
        ]
    )

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Blog Details'  />
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post">
                                <div className="inner-content">
                                    <div className="media style">
                                        <img src={require ('../assets/images/blog/blog-detail-1.jpg')} alt="Nufti"/>                       
                                    </div>
                                    <h2 className="title">Royal House Project</h2>
                                    <div className="sc-card-article">
                                        <div className="meta-info style">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={require ('../assets/images/avatar/avt-01.png')} alt="Nufti"/>
                                                </div>
                                                <div className="info">
                                                    <h5> Project Owner<Link className="text-color-3" to="author-profile.html"> John Hiva</Link> </h5>
                                                    <div className="date"> Date <span> 04/10/2022</span></div>                                           
                                                </div>
                                            </div>                                  
                                        </div>
                                    </div>
                
                                    <p className="text">A conflict arose between the project owner and a selected partner responsible for supplying key materials. The issue centered around the timeline and quality of the delivered goods. The project owner claimed that the materials were delayed and did not meet the agreed specifications, which impacted the overall progress of the project. Consequently, the owner sought to impose a penalty as stipulated in the smart contract and initiate a new tender to replace the partner.

The partner, however, disputed the claims, arguing that the materials were delivered on time and met the required standards. They refused to accept the penalty, insisting that the project owner had unrealistic expectations. With both sides firmly holding their positions, the conflict escalated, requiring intervention through a third-party arbitrator to resolve the dispute fairly.

This case highlights the importance of clear communication, reliable quality control, and the need for a consensus mechanism in smart contracts for construction projects.</p>
                                    
                                    
                                   
                                                                                                                          
                                 </div>  
                                 <div id="comments">
                                     <ol className="comment-list">
                                            {
                                                dataComment.map((item,index) => (
                                                    <li className="comment-01 flex mb-44" key={index}>
                                                        <div className="comment-avatar">
                                                            <img src={item.img} alt="images" />
                                                        </div>
                                                        <div className="comment-content">
                                                            <div className="comment-meta">                                                  
                                                                <div className="comment-author mb-18"><h3>{item.name} </h3></div>                                                                                                                                                 
                                                            </div>
                                                            <div className="comment-text">
                                                                <p>{item.content}</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </li>  
                                                ))
                                            }
                                    </ol>
                                    <div id="respond" className="respond-comment">
                                        <div className="title-comment2 font-20-600">Write your judgment</div>
                                        <form id="contactform" className="comment-form form-submit"
                                            action="#" method='post' acceptCharset="utf-8"
                                            >
                                            
                                            <fieldset className="message-wrap">
                                                <textarea id="comment-message" name="message" rows="3" tabIndex="4"
                                                    placeholder="My Opinion"
                                                 required></textarea>
                                            </fieldset>
                                           
                                            <button name="submit" type="submit" id="comment-reply" style={{marginRight:"4em"}}
                                                className="button btn-submit-comment"><span>Owner's Claim Approved </span></button>
                                                 <button name="submit" type="submit" id="comment-reply"
                                                className="button btn-submit-comment"><span>Partner's Claim Approved </span></button>
                                                
                                        </form>
                                    </div>
                                </div>                             
                            </article>        
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                                <div className="inner-side-bar">                                       
                                    
                                    <div className="widget widget-categories style"> 
                                        <div className="widget-title title-list">
                                            Uploaded Files
                                        </div>                                                                                                                        
                                        <ul>
                                            {
                                                dataServices.map((item,index) => (
                                                    <li  key={index}>
                                                        <Link to={item.link}>{item.text}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                   
                                    
                                    
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default JuryDetails;