
import Home1 from "./Home1";
import Explore from "./Explore";
import About from "./About";
import Author from "./Author";
//import Profile from "./Profile";
//import EditProfile from "./EditProfile";
import EditProfileClient from "./EditProfile-Client";
import EditProfileFreelancer from "./EditProfile-Freelancer";
import ClientLogin from "./ClientLogin";
import ClientRegister from "./ClientRegister";
import FreelancerLogin from "./FreelancerLogin";
import FreelancerRegister from "./FreelancerRegister";
import Contact from "./Contact";
import ClientDashboard from "./ClienDashboard";
import Applicants from "./Applicants";
import ProjectProposal from "./ProjectProposal";
import ClientProjectManagement from "./ClientProjectManagement";
import ClientProjectArchive from "./ClientProjectArchive";
import FreelancerDashboard from "./FreelancerDashboard";
import ProjectDetail from "./ProjectDetail";
import BuyCoinClient from "./BuyCoinClient";
import BuyCoinFreelancer from "./BuyCoinFreelancer";
import FreelancerProjectManagement from "./FreelancerProjectManagement";
import FreelancerProjectArchive from "./FreelancerProjectArchive";
import ProjectDetailClient from "./ProjectDetailClient";
import EditProject from "./EditProject";
import Requirement from "./Requirements.jsx";
import RequirementsEstimation from "./RequirementsEstimation.jsx";
import CreateItem from "./CreateItem.jsx"
import LiveAuction1 from "./LiveAuction.jsx";
import LiveAuctionDetails from "./LiveAuctionDetails.jsx"
import Jury from "./Jury.jsx";
import JuryDetails from "./JuryDetails.jsx";


const routes = [
    { path: '/', component: <Home1 />},
    { path: '/explore', component: <Explore />},
    { path: '/about', component: <About />},
    { path: '/author', component: <Author />},
    //{ path: '/profile', component: <Profile />},
   // { path: '/editProfile', component: <EditProfile />},
    { path: '/editProfile-owner', component: <EditProfileClient />},
    { path: '/editProfile-partner', component: <EditProfileFreelancer />},
    { path: '/login-owner', component: <ClientLogin />},
    { path: '/register-owner', component: <ClientRegister />},
    { path: '/login-partner', component: <FreelancerLogin />},
    { path: '/register-partner', component: <FreelancerRegister />},
    { path: '/contact', component: <Contact />},
    { path: '/dashboard-owner', component: <ClientDashboard />},
    { path: '/project-applicants', component: <Applicants />},
    { path: '/project-proposal', component: <ProjectProposal />},
    { path: '/owner-project-management', component: <ClientProjectManagement />},
    { path: '/owner-project-archive', component: <ClientProjectArchive />},
    { path: '/dashboard-partner', component: <FreelancerDashboard />},
    { path: '/project-detail', component: <ProjectDetail />},
    { path: '/buy-coin-owner', component: <BuyCoinClient />},
    { path: '/buy-coin-partner', component: <BuyCoinFreelancer />},
    { path: '/partner-project-management', component: <FreelancerProjectManagement />},
    { path: '/partner-project-archive', component: <FreelancerProjectArchive />},
    { path: '/owner-project-detail', component: <ProjectDetailClient />},
    { path: '/owner-edit-request', component: <EditProject />},
    { path: '/requirements', component: <Requirement />},
    { path: '/requirements-valuation', component: <RequirementsEstimation />},
    { path: '/set-auction', component: <CreateItem />},
    { path: '/live-auctions', component: <LiveAuction1 />},
    { path: '/liveAuctionDetails', component: <LiveAuctionDetails />},
    { path: '/jury', component: <Jury />},
    { path: '/jury-details', component: <JuryDetails />},











  ]
  
  export default routes;