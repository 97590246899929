import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { fetchProposalById, AcceptFreelancer } from '../../../api/projectProposalApi';

import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/author-profile.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import down2Button from '../../button/download2';

const ProposalProfile = (props) => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const data = props.data;
    const [modalShow, setModalShow] = useState(false);



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const proposalId = searchParams.get('id');

    const [proposal, setProposal] = useState(null);

    const [freelancerCost, setFreelancerCost] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');

    useEffect(() => {
        const fetchProposal = async () => {
            try {
                const proposalData = await fetchProposalById(proposalId);
                setProposal(proposalData);
            } catch (error) {
                console.error('Error fetching Proposal details:', error);
            }
        };
        fetchProposal();
    }, [proposalId]);


    console.log('Proposal by id in front:', proposal);

    //fakeData for running front
    const sampleProposal = [
        {
            project_id: "101",
            title: "Redesign Company Website",
            freelancer_first_name: "John",
            freelancer_last_name: "Doe",
            creation_date: "2024-08-20",
            budget: 2500,
            status: "start1",
            num_proposals: 5,
            progress: 70, // Progress percentage
            proposal_description: "Dear [Project Owner's Name], <br /> We are pleased to submit our proposal for the supply of [specific goods/service] required for your construction project. Based on the provided specifications, our team has thoroughly reviewed the timeline and budget for this engagement. We are confident that we can deliver the required materials within the estimated time frame of [X days/weeks], ensuring that the project stays on schedule. Furthermore, our proposed cost aligns with your budget estimation, keeping the project on budget while maintaining high-quality standards for the materials. Additionally, our team has a strong track record in successfully completing similar projects ahead of time. If there are any changes or adjustments required to meet your specific needs, we are flexible and open to collaboration. We look forward to the opportunity to contribute to your project's success and deliver exceptional results.Best regards,[Partner's Name] [Company Name]",
            freelancer_cost: 2200,
            freelancer_time: 20,
        },

    ]

    //proposal submission

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            // Submit the proposal to the backend
            await AcceptFreelancer({
                projectId: proposal[0].project_id,
                freelancerEmail: proposal[0].freelancer_email,
                freelancerFirstName: proposal[0].freelancer_first_name,
                freelancerLastName: proposal[0].freelancer_last_name,
                projectPrice: proposal[0].freelancer_cost,
                status: 'doing',
            });

            setRequestSuccess(true); // Set the registration success state to true

            // Show success notification
            toast.success('Congratulation, Start Your Project!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = `/client-project-management?id=${proposal[0].project_id}`;
            }, 3000);

        } catch (error) {
            console.error('Error Starting Cooperation:', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
            } else {
                toast.error('Error Starting Cooperation!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };
    {/*
    if (!proposal) {
        return <div>Loading...</div>;
    }
*/}

    return (
        <Fragment>
            <div className="tf-section flat-author-profile flat-explore flat-auctions " style={{ marginBottom: '0em' }}>
                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex">
                            <div className="feature-profile flex">
                                <div className="img-box relative">
                                    <img className="avatar" src={img1} alt="" />
                                </div>
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{sampleProposal[0].freelancer_first_name} {sampleProposal[0].freelancer_last_name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tf-section flat-about" style={{ marginTop: '0em' }}>
                    <div className="container">
                        <div className="post">
                            <h2 className="title-about text-t">Detail of Proposal</h2>
                            {/* <p className="text-1">{sampleProposal[0].proposal_description}</p> */}
                            <p>
                                Dear [Project Owner's Name], <br /> <br />

                                We are pleased to submit our proposal for the supply of [specific goods/service] required for your construction project. Based on the provided specifications, our team has thoroughly reviewed the timeline and budget for this engagement. We are confident that we can deliver the required materials within the estimated time frame of [X days/weeks], ensuring that the project stays on schedule. Furthermore, our proposed cost aligns with your budget estimation, keeping the project on budget while maintaining high-quality standards for the materials. <br />

                                Additionally, our team has a strong track record in successfully completing similar projects ahead of time. If there are any changes or adjustments required to meet your specific needs, we are flexible and open to collaboration. We look forward to the opportunity to contribute to your project's success and deliver exceptional results. <br />

                                Best regards,<br />
                                [Partner's Name] <br />
                                [Company Name] 
                                <down2Button />
                            </p>
                            <button
                                    style={{width:"10em", height:"3em", padding:"0.1em", color:"primary", backgroundColor:"black", marginTop:"1em"}}
                                > Download Attached Files</button>

                            <div className="box" style={{marginTop:'4em'}}>
                                <div className="title-text fs-18 fw-4 text-color-3" style={{marginBottom:'0'}} >My suggested price for this project is: {sampleProposal[0].freelancer_cost} EUR </div>
                                <div className="title-text fs-18 fw-4 text-color-3">My suggested duration for this project is: {sampleProposal[0].freelancer_time} Days </div>

                                <button
                                    //onClick={handleAccept}
                                    onClick={() => window.location.href = '/dashboard-owner'}

                                > Accept and start cooperation</button>
                            </div>
                        </div>
                        <div className="back-arrow"
                            style={{ cursor: "pointer", fontSize: "1.5em" }}
                            onClick={() => navigate(-1)}
                        >
                            &#8592; Back
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>

    );
};


export default ProposalProfile;
