import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Profile from '../components/layouts/clientdashboard/dashboard.jsx';
import dataProfile from '../assets/fake-data/data-liveaution.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import { fetchRequests } from '../api/clientDashboardApi.js'; // Import the fetchRequests function
import { fetchClientData } from '../api/clientApi.js'; // Import the fetchRequests function

import imgauthor1 from '../assets/images/avatar/avt-01.png';

import icon1 from '../assets/images/icon/author-check.svg';
import icon2 from '../assets/images/icon/icon-diamond.svg';

import img1 from '../assets/images/box-item/auctions-111.jpg';
import img2 from '../assets/images/box-item/auctions-2.jpg';
import img3 from '../assets/images/box-item/auctions-3.jpg';
import img4 from '../assets/images/box-item/auctions-4.jpg';
import img5 from '../assets/images/box-item/auctions-5.jpg';

function ClientDashboard(props) {
    const [profilePhoto, setProfilePhoto] = useState();

    //Fetch Project Request from Backend
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clientEmail = localStorage.getItem('email');
                const requests = await fetchRequests(clientEmail); // Fetch Requests data from backend API
                setRequestsData(requests[0]); // Set fetched Requests data to state

                const clientData = await fetchClientData(localStorage.getItem('email'));
                if (!clientData) {
                    // Redirect to /login-freelancer if freelancerData is not available
                    navigate('/register-client');
                } else {
                    // Set the first name and last name to localStorage
                    localStorage.setItem('firstName', clientData.first_name);
                    localStorage.setItem('lastName', clientData.last_name);
                    setProfilePhoto(clientData.client_photo_link)
                }

                // Set the first name and last name to localStorage
                console.log('client data in front:', clientData)
                // localStorage.setItem('firstName', clientData.first_name);
                // localStorage.setItem('lastName', clientData.last_name);
            } catch (error) {
                console.error('Error fetching Requests:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);

    const images = [img1, img2, img3, img4, img5];

    //sample data just for front working
    const sampleRequestsData = [
        {
            project_id: "101",
            title: "X Street 150 meter apartment",
            freelancer_first_name: "John",
            freelancer_last_name: "Doe",
            creation_date: "2024-08-20",
            budget: 2500,
            status: "start1",
            num_proposals: 5,
            progress: 70, // Progress percentage
        },
        {
            project_id: "108",
            title: "dream villa",
            freelancer_first_name: "John",
            freelancer_last_name: "Doe",
            creation_date: "2024-08-20",
            budget: 2500,
            status: "start2",
            num_proposals: 5,
            progress: 40, // Progress percentage
        },
        {
            project_id: "102",
            title: "concreting",
            freelancer_first_name: "Jane",
            freelancer_last_name: "Smith",
            creation_date: "2024-08-18",
            budget: 5000,
            status: "tendering1",
            num_proposals: 3,
            progress: 40, // Progress percentage
        },
        {
            project_id: "103",
            title: "Plastering and painting",
            freelancer_first_name: "Mike",
            freelancer_last_name: "Johnson",
            creation_date: "2024-08-15",
            budget: 1500,
            status: "tendering2",
            num_proposals: 7,
            progress: 40, // Progress percentage
        },
        {
            project_id: "105",
            title: "Electrical wiring",
            freelancer_first_name: "Mike",
            freelancer_last_name: "Johnson",
            creation_date: "2024-08-15",
            budget: 1500,
            status: "doing11",
            num_proposals: 7,
            progress: 40, // Progress percentage
        },
        {
            project_id: "106",
            title: "Cabinet supply",
            freelancer_first_name: "Mike",
            freelancer_last_name: "Johnson",
            creation_date: "2024-08-15",
            budget: 1500,
            status: "doing12",
            num_proposals: 7,
            progress: 40, // Progress percentage
        },
        {
            project_id: "107",
            title: "Royal Commercial Tower",
            freelancer_first_name: "Mike",
            freelancer_last_name: "Johnson",
            creation_date: "2024-08-15",
            budget: 100,
            status: "done1",
            num_proposals: 3,
            progress: 40, // Progress percentage
        },
        {
            project_id: "107",
            title: "Repairing the friend's house",
            freelancer_first_name: "Mike",
            freelancer_last_name: "Johnson",
            creation_date: "2024-08-15",
            budget: 150000,
            status: "done2",
            num_proposals: 7,
            progress: 40, // Progress percentage
            share: 20000,
        },
        // Add more sample data if needed
    ];

    // Map the fetched Requests data to match the structure of dataLiveaution
    const mappedRequestsData = sampleRequestsData.map((request, index) => ({
        id: `${request.project_id}`,
        img: images[index % images.length], // Use the modulo operator to cycle through the images
        tags: `${request.num_proposals} Recieved Proposals`,
        tags_progs: `${request.num_proposals} Recieved Updates`,
        tags_done1: `${request.num_proposals} Recieved Auctions`,
        imgauthor: imgauthor1,
        title: `${request.title}`, // Update with fetched Requests number
        author: `${request.freelancer_first_name} ${request.freelancer_last_name}`,
        date: `${request.creation_date}`,
        titleprice: `${(request.budget / 1).toFixed(2)} EUR`, // Update with fetched price
        titleprice_tend: `${(request.budget / 1).toFixed(2)} Coins`, // Update with fetched price
        titleprice_done1: `${(request.budget / 1).toFixed(2)} Coins`, // Update with fetched price
        btn: 'Check Proposals!',
        reviewbtn: 'Reviwe & Edit!',
        btn_pre: 'Check Requirements',
        reviewbtn_pre: 'Reviwe & Edit!',
        btn_progress: 'Check Progress',
        btn_done1: 'Set&Start Auction',
        reviewbtn_done1: 'Check Auctions',
        btn_done2: 'Review Project',
        reviewbtn_done2: 'Delete Project',
        icon1: icon1,
        icon2: icon2,
        titlecountdown: 'Ending Time -',
        status: `${request.status}`,
        progress: request.progress, // Include progress
        share:`${(request.share)} EUR`,
    }));

    // Determine the number of rows to display based on the length of the fetched Requests data
    const numRowsToShow = mappedRequestsData.length > 3 ? 3 : mappedRequestsData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedRequestsData = mappedRequestsData.slice(0, numRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedDataLiveaution = [...mappedRequestsData, ...slicedRequestsData.slice(mappedRequestsData.length)];


    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Dashboard' />
            {<Profile data={updatedDataLiveaution} avatar={profilePhoto} />}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default ClientDashboard;
