const dataFaq = [
    {
        id: 1,
        title: 'Simplified Project Financing',
        content: 'By partnering suppliers in the ownership of the project, Buildynamic creates a seamless flow of capital, reducing reliance on traditional financing and accelerating project timelines.',
    },
    {
        id: 2,
        title: 'Accurate Cost Estimation',
        content: 'Our CBS service provides highly accurate estimates for materials, manpower, and costs, helping you plan efficiently and avoid budget overruns.',
        show: 'show'
    },
    {
        id: 3,
        title: 'Transparency and Trust',
        content: 'Blockchain technology and smart contracts ensure transparency at every stage of the project, protecting all stakeholders and creating a trusted environment for transactions.',
    },
    {
        id: 4,
        title: 'Increased Liquidity Through Tokenization',
        content: 'Buildynamic’s tokenization system allows for fractional ownership, giving investors more flexibility and providing an innovative way to raise capital without giving up control.',
    },
    {
        id: 5,
        title: 'Efficient Resource Management',
        content: 'The detector and valuation algorithm optimizes resource allocation, ensuring that you’re working with the best suppliers and the most cost-effective solutions.',
    },
    {
        id: 6,
        title: 'Scalable and Secure',
        content: 'Our API-based smart contract generator and tokenization tools scale with your project, ensuring long-term security and efficiency, no matter the project size.',
    },
    {
        id: 7,
        title: 'Accurate Work Breakdown & Cost Estimation',
        content: 'Our CBS (Cost Breakdown Structure) tool automatically calculates the materials, land, manpower, and buffer costs based on the uploaded plans. Get accurate estimates in real time.',
    },
    {
        id: 8,
        title: 'Tokenize Your Project for Financing',
        content: 'The project can then be tokenized, converting project costs into tokens that represent fractional ownership. Investors can purchase these tokens to fund the project, ensuring flexible and diversified financing.',
    },
    {
        id: 9,
        title: 'Dynamic Resource Detection',
        content: 'Our Valuation Algorithm identifies required components and suggests suppliers and resources, optimized for cost and availability, ensuring efficient project execution.',
    },
    {
        id: 10,
        title: 'Smart Contracts & Blockchain Security',
        content: 'Buildynamic uses blockchain-powered smart contracts to ensure transparency and automatic fulfillment of agreements, reducing administrative overhead and improving trust among stakeholders.',
    },
    {
        id: 11,
        title: 'Real-Time Progress Tracking',
        content: 'Monitor project progress and financial updates in real-time through our dashboard, allowing you to stay on top of every detail from anywhere.',
    },
    {
        id: 12,
        title: 'Blockchain & Smart Contracts',
        content: 'We use blockchain technology to tokenize construction projects, ensuring secure, transparent, and immutable transactions. Smart contracts automate agreements and facilitate trust between stakeholders.',
    },
    {
        id: 13,
        title: 'Cloud-Based Infrastructure',
        content: 'Our platform is built on robust cloud infrastructure, providing scalable, secure, and highly available services for users to access anywhere, anytime.',
    },
    {
        id: 14,
        title: 'API Integration',
        content: 'Buildynamic offers API-based services, allowing easy integration with external platforms, such as smart contract generators and financial management tools, ensuring flexibility and compatibility with various software.',
    },
    {
        id: 15,
        title: 'AI-Powered Valuation Algorithm',
        content: 'Our proprietary AI algorithm dynamically calculates resource needs, cost breakdowns, and optimizes the supply chain based on real-time data, ensuring precise financial modeling and efficiency.',
    },
    {
        id: 16,
        title: 'Data Security & Encryption',
        content: 'We prioritize the security of sensitive project data with end-to-end encryption and industry-standard cybersecurity practices, ensuring user data is protected at all stages.',
    },
    {
        id: 17,
        title: 'Real-Time Analytics Dashboard',
        content: 'Built with modern frontend frameworks and data visualization tools, our intuitive dashboard provides real-time insights into project progress, financials, and performance metrics.',
    },
    {
        id: 18,
        title: 'Tokenization Engine',
        content: 'Our tokenization system, backed by blockchain technology, converts project costs into fractional tokens, facilitating flexible financing and democratizing investment opportunities.',
    },
]

export default dataFaq;