import React, { useState, useEffect } from 'react';
import '../scss/components/section.scss';
import { Link } from "react-router-dom";
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import img1 from '.././assets/images/box-item/author-profile.jpg';
// Backend Connection
import { editFreelancer } from "../api/auth";

// Showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getFreelancerProfile } from '../api/freelancerProfileApi';

// For photo
import { uploadProfilePhoto, fetchProfilePhoto, deleteProfilePhoto } from '../api/freelancerProfileApi';
import { fileBaseUrl } from '../api/constants.js';
import { Container, Dropdown } from "react-bootstrap";

function EditProfileFreelancer() {
    const [profilePhoto, setProfilePhoto] = useState(img1);
    const [userData, setUserData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        time_zone: '',
        tags: '',
        client_photo_link: '',
    });

    //Land Owner
    const [selectedLandOwner, setSelectedLandOwner] = useState("Click to Select");
    const [landOwnerList] = useState(
        [
            {
                id: 1,
                title: "No",
            },
            {
                id: 2,
                title: "Yes",
            },
        ]
    )

    // State for multi-selection
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedMachinery, setSelectedMachinery] = useState([]);

    // Example data for materials, services, and machinery
    const materials = ["Concrete", "Steel", "Bricks", "Wood", "Glass"];
    const services = ["Wiring", "Plumbing", "Painting", "Tiling", "Roofing"];
    const machinery = ["Excavator", "Bulldozer", "Cranes", "Forklift", "Generators"];

    const handleMaterialChange = (e) => {
        const value = e.target.value;
        setSelectedMaterials(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const handleServiceChange = (e) => {
        const value = e.target.value;
        setSelectedServices(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const handleMachineryChange = (e) => {
        const value = e.target.value;
        setSelectedMachinery(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const userEmail = localStorage.getItem('email');
            if (userEmail) {
                const profileData = await getFreelancerProfile(userEmail);
                if (profileData) {
                    setUserData(profileData[0]);
                    if (profileData[0].client_photo_link) {
                        setProfilePhoto(`${fileBaseUrl}/${profileData[0].client_photo_link}`);
                    }
                }
            }
        };
        fetchData();
    }, []);
    console.log('previouse user data:', userData);


    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };


    const handleEdit = async (event) => {
        //userData.email = localStorage.getItem('email');
        event.preventDefault();
        try {
            console.log('userData that is sending to back:', userData);
            const response = await editFreelancer(userData);
            if (response && response.success) {
                toast.success('Edit successful!', { position: 'top-right', autoClose: 3000 });
                setTimeout(() => window.location.href = '/dashboard-freelancer', 3000);
            } else {
                toast.error(`Edit failed: ${response.message}`, { position: 'top-right', autoClose: 3000 });
            }
        } catch (error) {
            console.error('Edit error', error);
            toast.error('Edit failed!', { position: 'top-right', autoClose: 3000 });
        }
    };



    const handlePhotoUpload = async (event) => {
        event.preventDefault();
        const email = localStorage.getItem('email');
        if (!email) {
            console.log("No email found in local storage.");
            return;
        }
        const formData = new FormData();
        formData.append('profilePhoto', event.target.files[0]);
        formData.append('email', email);
        try {
            const response = await uploadProfilePhoto(email, formData);
            const photoPath = `${fileBaseUrl}/${response.photoPath}`; // Ensure the full URL is set
            setProfilePhoto(photoPath);
            toast.success("Profile photo uploaded successfully!");
        } catch (error) {
            console.error("Error uploading profile photo:", error);
            toast.error("Error uploading profile photo.");
        }
    };

    const handlePhotoDelete = async (event) => {
        event.preventDefault();
        const email = localStorage.getItem('email');
        if (!email) {
            console.log("No email found in local storage.");
            return;
        }

        try {
            await deleteProfilePhoto(email);
            setProfilePhoto(img1);
            toast.success("Profile photo deleted successfully!");
        } catch (error) {
            console.error("Error deleting profile photo:", error);
            toast.error("Error deleting profile photo.");
        }
    };
    const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false);

    const handleItemClick = (title) => {
        setSelectedLandOwner(title);
        setIsPeriodMenuOpen(false);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'collaborationPeriod', value: title } });
    };
    const handlePeriodMenuToggle = (isOpen) => {
        setIsPeriodMenuOpen(isOpen);
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <ToastContainer />
            <PageTitle title='Edit Profile' />
            <div className="tf-section flat-explore flat-edit-profile flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="author-profile">
                                <div className="feature-profile center">
                                    <div className="wrap-img flex j-content">
                                        <div className="img-box relative">
                                            <img className="avatar" id="profileimg" src={profilePhoto} alt="" />
                                        </div>
                                    </div>
                                    <div id="upload-profile">
                                        <Link className="btn-upload" onChange={handlePhotoUpload}>Upload Your Photo</Link>
                                        <input id="tf-upload-img" type="file" onChange={handlePhotoUpload} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="form-upload-profile post">
                                <h3 className="title-two fw-6">Update Your Information</h3>
                                <form className="form-profile" onSubmit={handleEdit}>
                                    <div className="form-infor-profile">
                                        <div className="form-infor flex flat-form">
                                            <div className="info-account">
                                                <fieldset>
                                                    <p className="title-infor-account">First Name</p>
                                                    <input type="text" placeholder="Enter Your First Name"
                                                        id="first_name" className="tb-my-input" name="first_name"
                                                        value={userData.first_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>

                                                <fieldset>
                                                    <p className="title-infor-account">Country</p>
                                                    <input type="text" placeholder="Enter Your First Name"
                                                        id="first_name" className="tb-my-input" name="first_name"
                                                        value={userData.first_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">City</p>
                                                    <input type="text" placeholder="Enter Your First Name"
                                                        id="first_name" className="tb-my-input" name="first_name"
                                                        value={userData.first_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>




                                            </div>
                                            <div className="info-social">
                                                <fieldset>
                                                    <p className="title-infor-account">Last Name</p>
                                                    <input type="text" placeholder="Enter Your Last Name"
                                                        id="last_name" className="tb-my-input" name="last_name"
                                                        value={userData.last_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>

                                                <fieldset>
                                                    <p className="title-infor-account">Phone</p>
                                                    <input type="text" placeholder="+55 12333 444 55" name="phone"
                                                        id="phone" className="tb-my-input"
                                                        value={userData.phone}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '1.5em', zIndex: 2 }}  >
                                                    <p className="title-infor-account">Do you own land?</p>
                                                    <div id="item-create1" >
                                                        <Dropdown
                                                            style={{ zIndex: 2 }}
                                                        //className='dropdown-item' 
                                                        >
                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                style={{ minWidth: '21.5em', zIndex: 2 }}
                                                            //className='dropdown-item'
                                                            >
                                                                {selectedLandOwner}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 2 }}
                                                            >
                                                                {landOwnerList.map(data => (
                                                                    <Dropdown.Item
                                                                        style={{ width: '21.5em', zIndex: 2 }}
                                                                        key={data.id} onClick={() => handleItemClick(data.title)}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>

                                            </div>
                                        </div>


                                        {/* Services Selection */}
                                        <fieldset>
                                            <p className="title-infor-account">Choose your services/expertise:</p>
                                            {services.map((service, index) => (
                                                <label key={index}
                                                style={{ fontSize: '1.5em', marginRight: '0' }}

                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={service}
                                                        checked={selectedServices.includes(service)}
                                                        onChange={handleServiceChange}
                                                        style={{ transform: 'scale(1.5)', marginLeft: '1em', marginTop: "1em" }}

                                                    />
                                                    {service}
                                                </label>
                                            ))}
                                        </fieldset>

                                        {/* Materials Selection */}
                                        <fieldset style={{marginTop:"2em"}}>
                                            <p className="title-infor-account">Choose what materials you provide:</p>
                                            {materials.map((material, index) => (
                                                <label key={index}
                                                style={{ fontSize: '1.5em', marginRight: '0' }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={material}
                                                        checked={selectedMaterials.includes(material)}
                                                        onChange={handleMaterialChange}
                                                        style={{ transform: 'scale(1.5)', marginLeft: '1em', marginTop: "1em" }}

                                                    />
                                                    {material}
                                                </label>
                                            ))}
                                        </fieldset>

                                        {/* Machinery Selection */}
                                        <fieldset  style={{marginTop:"3em"}}>
                                            <p className="title-infor-account">Choose what machinery/equipment you provide:</p>
                                            {machinery.map((machine, index) => (
                                                <label key={index}
                                                    style={{ fontSize: '1.5em', marginRight: '0' }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={machine}
                                                        checked={selectedMachinery.includes(machine)}
                                                        onChange={handleMachineryChange}
                                                        style={{ transform: 'scale(1.5)', marginLeft: '1em', marginTop: "1em" }}
                                                    />
                                                    {machine}
                                                </label>
                                            ))}
                                        </fieldset>

                                        {/* File Upload for Resume or Material Data */}
                                        <fieldset style={{marginTop:"3em"}}>
                                            <p className="title-infor-account">Upload your resume or materials data</p>
                                            <input type="file" onChange={handleFileUpload} />
                                        </fieldset>

                                        <fieldset style={{marginTop:"3em"}}>
                                            <p className="title-infor-account">About You or Your Brand</p>
                                            <textarea tabIndex="4" rows="3"
                                                id="tags" className="tb-my-input" name="tags"
                                                value={userData.tags}
                                                onChange={handleInputChange}></textarea>
                                        </fieldset>

                                    </div>
                                    <button
                                        className="tf-button-submit mg-t-15"
                                        type="button"
                                        onClick={() => window.location.href = '/dashboard-partner'}>
                                        Save & Update Information
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer data={dataFooter} />
            <Bottom classection='widget-social-footer' />
        </div>
    );
}

export default EditProfileFreelancer;
