import React, { useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx'
import Banner from '../components/banner/banner-home1';
import dataBanner from '../assets/fake-data/data-banner-home';
import Category from '../components/cetagories/cetagorie-v2';
import dataCategory from '../assets/fake-data/data-category';
import Liveaution from '../components/liveauctions/liveauction-v1';
import dataLiveaution from '../assets/fake-data/data-liveaution';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import Liveaution2 from '../components/liveauctions/liveauction-v4';
import dataLiveaution2 from '../assets/fake-data/data-liveaution';
import Friendly from '../components/friendly/flat-friendly-v2';
import dataFriendly from '../assets/fake-data/data-friendly';
import NewHome from '../components/newhome/index.jsx';
import Faq from './Faq.jsx';
import img1 from '../assets/images/icon/check.png';
import img2 from '../assets/images/blog/blog-detail-4.png';

import img3 from '../assets/images/avatar/avt-01.png';
import img4 from '../assets/images/avatar/avt-02.png';
import img5 from '../assets/images/avatar/avt-03.png';
import { Link } from "react-router-dom";


function HomeOne(props) {
    const [dataRecent] = useState(
        [
            {
                img: img1,
                title: 'Funding Shortfalls:',
                desc: 'Construction projects often struggle to secure financing, leading to delays and halted progress.',
            },
            {
                img: img1,
                title: 'Supplier Involvement:',
                desc: 'Suppliers are frequently disconnected from the success of the project despite being crucial contributors.',
            },
            {
                img: img1,
                title: 'Lack of Transparency:',
                desc: "Investors, suppliers, and stakeholders lack a unified platform to view the project's progress and financial health.",
            },
            {
                img: img1,
                title: 'Complex Resource Management:',
                desc: 'Accurate tracking of materials, labor, and capital investments is complicated without integrated technology.',
            }
        ]
    )
    const [dataComment] = useState(
        [
            {
                img: img2,
                name: ' Monsur Rahman Lito',
                content: 'Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis. Curabitur ultrices justo ac leo facilisis tincidunt. Maecenas et dui eget nisl ornare scelerisque.',
            },
            {
                img: img2,
                name: ' Monsur Rahman Lito',
                content: 'Phasellus ac consequat turpis, sit amet fermentum nulla. Donec dignissim augue nunc. Praesent bibendum erat ac lectus molestie lobortis. Curabitur ultrices justo ac leo facilisis tincidunt. Maecenas et dui eget nisl ornare scelerisque.',
            },
        ]
    )

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            {<NewHome />}
            <div className="tf-section flat-about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="wrap-img">
                                <div className="media">
                                    <img src={require('../assets/images/box-item/Designer.jpeg')} alt="Nufti" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="post">
                                <h2 className="title-about text-t">What's Buildynamic?</h2>
                                <div className="title-text fs-18 fw-4 text-color-3">Buildynamic is dedicated to solving the challenge of financing construction projects by offering a unique solution—partnering suppliers in the ownership of the project.</div>
                                <p className="text-1"> By integrating blockchain, automation, and smart contracts, we create a dynamic ecosystem where material suppliers, machinery providers, and manpower can contribute directly to the project and, in return, hold a stake in its success. This innovative approach ensures that construction projects are efficiently funded, while all contributors benefit from their involvement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div >
                            <article className="post">
                                <div className="inner-content">
                                    <h2>Why Buildynamic Was Created
                                    </h2>
                                    <div className="title-text fs-18 fw-4 text-color-3">Solving the Construction Financing Gap</div>

                                    <p className="text">The construction industry faces significant hurdles when it comes to financing projects, especially in today’s economy. Traditional funding models create delays, inefficiencies, and limit the access of smaller developers to critical resources. Buildynamic was developed to directly address these challenges by creating a more integrated and collaborative financing ecosystem.</p>
                                    <div className="img-box" >
                                        <div className="wrap-check" style={{ width: "70em" }}>
                                            {
                                                dataRecent.map((item, index) => (
                                                    <ul className="check" key={index}>
                                                        <li><img src={item.img} alt="images" /></li>
                                                        <li><h3>{item.title}</h3> <p>{item.desc}</p></li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                        <div className="img-blog" >
                                            <div className="media">
                                                <img src={require('../assets/images/box-item/problem.jpeg')} alt="Nufti" />
                                            </div>
                                        </div>
                                    </div>



                                    {/* Services */}
                                    <h2 style={{ marginTop: "5em", marginBottom: "1em" }}>Comprehensive Services for Modern Construction</h2>
                                    <div className="inner-content">
                                        <div className="post-image">
                                            <div className="thumb">
                                                <img src={require('../assets/images/icon/services/cbs1.png')} alt="Nufti" />
                                            </div>
                                            <div className="box-title">
                                                <div className="title-author text-color-3" style={{ marginBottom: "0" }}>Buildynamic CBS</div>
                                                <p style={{ fontWeight: "bold" }}>Precise Project Costing and Resource Management </p>
                                                <p>Upload building plans to generate accurate material, manpower, and cost estimations. Our dashboard provides detailed breakdowns for everything from land requirements to buffer costs, ensuring smooth project execution.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-content">
                                        <div className="post-image">
                                            <div className="thumb">
                                                <img src={require('../assets/images/icon/services/valuation1.png')} alt="Nufti" />
                                            </div>
                                            <div className="box-title">
                                                <div className="title-author text-color-3" style={{ marginBottom: "0" }}>Buildynamic Detector & Valuation Algorithm</div>
                                                <p style={{ fontWeight: "bold" }}>Smart Valuation for Optimized Resource Allocation</p>
                                                <p>Identify the best brands, suppliers, and manpower for your project. Our dynamic valuation algorithm offers real-time cost and resource insights, giving you a competitive edge in budgeting and procurement.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-content">
                                        <div className="post-image">
                                            <div className="thumb">
                                                <img src={require('../assets/images/icon/services/contract1.png')} alt="Nufti" />
                                            </div>
                                            <div className="box-title">
                                                <div className="title-author text-color-3" style={{ marginBottom: "0" }}>Smart Contract Generator (API-Based)</div>
                                                <p style={{ fontWeight: "bold" }}>Automated, Secure Contracts for Every Phase of the Project</p>
                                                <p>Simplify and automate contract creation with our smart contract generator, offering a secure and transparent way to manage agreements between all project stakeholders.                       </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner-content">
                                        <div className="post-image">
                                            <div className="thumb">
                                                <img src={require('../assets/images/icon/services/token1.png')} alt="Nufti" />
                                            </div>
                                            <div className="box-title">
                                                <div className="title-author text-color-3" style={{ marginBottom: "0" }}>Tokenization System</div>
                                                <p style={{ fontWeight: "bold" }}>Fractional Ownership Through Tokenization</p>
                                                <p>Transform your project’s costs into digital tokens, allowing investors to own shares of the project’s future value. Buildynamic’s tokenization platform opens new avenues for liquidity, making it easier to attract investments and manage finances.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="infor-row flex">
                                        <div className="tags flex">
                                            <h4 className="">Tag Here</h4>
                                            <div className="flex font">
                                                <Link to="#">Creative</Link>
                                                <Link to="#" className="activ">Agency</Link>
                                                <Link to="#">Business</Link>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    */}

                                    {/*
                                <div id="comments">
                                    <div className="title-comment font-20-600">02 Comments</div>
                                    <p className="mb-36"> Nunc velit metus, volutpat elementum euismod eget, cursus nec nunc.</p>
                                    <ol className="comment-list">
                                        {
                                            dataComment.map((item, index) => (
                                                <li className="comment-01 flex mb-44" key={index}>
                                                    <div className="comment-avatar">
                                                        <img src={item.img} alt="images" />
                                                    </div>
                                                    <div className="comment-content">
                                                        <div className="comment-meta">
                                                            <div className="comment-author mb-18"><h3>{item.name} </h3></div>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>{item.content}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>
                                */}
                                    {<Faq />}
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default HomeOne;