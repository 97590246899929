import axios from 'axios';

import { API_URL } from './constants';


export const fetchData = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/client-project-management/${projectId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendMessage = async (messageData) => {
    try {
        await axios.post(`${API_URL}/client-project-management/send-message`, messageData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        throw error;
    }
};

export const finishProject = async (projectId) => {
    try {
      await axios.put(`${API_URL}/client-project-management/${projectId}`, { status: 'done' });
      // Optionally, you can handle success actions here, such as displaying a message or updating state
    } catch (error) {
      throw error;
    }
  };
