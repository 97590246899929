import React, { useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import { Link } from 'react-router-dom';
//Bckend Connection
import { registerClient } from "../api/auth";

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ClientRegister() {

    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });

    const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state for success message

    const handleRegister = () => {

        window.location.href = '/editProfile-owner'; // Redirect after successful login
    };

    /*
    const handleRegister = async () => {
        try {
            const response = await registerClient(userData);
            // Handle the response, e.g., show a success message, redirect, etc.
            console.log('Registration successful', response);
            setRegistrationSuccess(true); // Set the registration success state to true
            localStorage.setItem('email',userData.email);
            localStorage.setItem('password',userData.password);

            // Show success notification
            toast.success('Registration successful!', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.setItem("email", userData.email);
            localStorage.setItem("password", userData.password);

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = '/editProfile-client';
            }, 2500);
        } catch (error) {
            // Handle registration error, e.g., display an error message
            console.error('Registration error', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;

                if (errorMessage === 'User already exists') {
                    toast.error('User with this email already exists!', {
                        // Toast configuration for duplicate email error
                    });
                } else if (errorMessage === 'Invalid email format') {
                    toast.error('Please enter a valid email address!', {
                        // Toast configuration for invalid email format error
                    });
                } else if (errorMessage === 'Inappropriate password format') {
                    toast.error('Password should have minimum 8 characters!', {
                        // Toast configuration for inappropriate password format error
                    });
                }
            } else {
                toast.error('Registration failed!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };
*/

    const handleInputChange = (e) => {
        // Update the userData state when input fields change
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Client Register' />
            <div className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img src={require('../assets/images/backgroup-secsion/hand.png')} alt="Nufti" className="absolute mark-login" />

                                    <div className="heading-login register">
                                        <h2 className="fw-5">Register At Buildynamics</h2>
                                        <div className="flex" style={{ position: 'relative' }}>
                                            <p>Do you have an account? </p>
                                            <Link to="/login-owner" className="text-p text-color-3 fw-6" style={{ position: 'relative', zIndex: 1 }}>
                                                Login
                                            </Link>
                                        </div>                                    </div>
                                    <form>
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login">
                                                <fieldset>
                                                    <p className="title-infor-account">Email</p>
                                                    <input type="email" id="email" className="tb-my-input" name="email" placeholder="info@gmail.com" aria-required="true" required=""
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                    />
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input type="password" name="password" className="tb-my-input" id="password" placeholder="*********************************" aria-required="true" required=""
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                    />
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Re-Password</p>
                                                    <input type="password" name="password" className="tb-my-input" id="password" placeholder="*********************************" aria-required="true" required=""
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                    />
                                                </fieldset>
                                                <div className="row-form style-1 flex-two">
                                                    <label className="flex align">
                                                        <input type="checkbox" />
                                                        <span className="btn-checkbox flex-two"></span>
                                                        <span className="text-p text-color-7">Remember Me</span>
                                                    </label>
                                                    {/*}
                                                    <Link to="#" className="forgot-pass text-p text-color-7">Forgot Password?</Link>
    {*/}
                                                </div>
                                                <Link >
                                                    <button
                                                        onClick={handleRegister}
                                                    >Register</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default ClientRegister;
