import React from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const EstimationProfile = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Sample requirements data
  const requirements = [
    { id: 1, category: "Manpower", title: "Expert Painter", cost: 5000, percentage: 10 },
    { id: 2, category: "Manpower", title: "Assistant Painter", cost: 2000, percentage: 4 },
    { id: 3, category: "Machines", title: "Crane 5-tons", cost: 15000, percentage: 30 },
    { id: 4, category: "Materials", title: "Cement Bags", cost: 1000, percentage: 2 },
    // Add more items as needed
  ];

  const totalTokens = 100000; // Total tokens for the project

  // Calculate the total cost and tokens
  const totalCost = requirements.reduce((acc, item) => acc + item.cost, 0);
  const requirementsWithTokens = requirements.map((item) => ({
    ...item,
    tokens: Math.round((item.percentage / 100) * totalTokens),
  }));

  // Prepare data for Pie Chart
  const pieData = {
    labels: requirementsWithTokens.map((item) => item.title),
    datasets: [
      {
        data: requirementsWithTokens.map((item) => item.percentage),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"], // Modern and attractive colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      },
    ],
  };

  const tableHeaderStyle = { color: "white", fontWeight: "bold", fontSize: "1.2em", borderBottom: "2px solid white" };
  const tableBodyStyle = { color: "white", borderBottom: "1px solid white" };

  return (
    <Container maxWidth="md" style={{ marginTop: "15em" }}>
      <Typography variant="h4" gutterBottom>Project Requirements Valuation</Typography>

      {/* Requirements Table */}
      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white", marginBottom: "2em", marginTop: "2em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Number</TableCell>
              <TableCell style={tableHeaderStyle}>Category</TableCell>
              <TableCell style={tableHeaderStyle}>Requirement Title</TableCell>
              <TableCell style={tableHeaderStyle}>Cost</TableCell>
              <TableCell style={tableHeaderStyle}>Percentage of Project Value</TableCell>
              <TableCell style={tableHeaderStyle}>Number of Tokens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requirementsWithTokens.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell style={tableBodyStyle}>{index + 1}</TableCell>
                <TableCell style={tableBodyStyle}>{item.category}</TableCell>
                <TableCell style={tableBodyStyle}>{item.title}</TableCell>
                <TableCell style={tableBodyStyle}>{`$${item.cost.toLocaleString()}`}</TableCell>
                <TableCell style={tableBodyStyle}>{`${item.percentage}%`}</TableCell>
                <TableCell style={tableBodyStyle}>{item.tokens}</TableCell>
              </TableRow>
            ))}
            {/* Total Row */}
            <TableRow>
              <TableCell style={tableHeaderStyle} colSpan={3}>Total</TableCell>
              <TableCell style={tableHeaderStyle}>{`$${totalCost.toLocaleString()}`}</TableCell>
              <TableCell style={tableHeaderStyle}>100%</TableCell>
              <TableCell style={tableHeaderStyle}>{totalTokens}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pie Chart */}
      <Typography variant="h5" gutterBottom>Requirements Distribution</Typography>
      <div style={{ width: "50%", height: "50%", margin: "0 auto", marginTop: "2em" }}>
        <Pie data={pieData} />
      </div>
      <div className="back-arrow"
        style={{ cursor: "pointer", fontSize: "1.5em" }}
        onClick={() => navigate(-1)}
      >
        &#8592; Back & Edit Requirements
      </div>
      <h4 style={{ marginBottom: "1em", marginTop: "4em" }}>Or if you are sure of all the requirements, start tendering now to find your best partners!</h4>
      <div>
        <button
          onClick={() => window.location.href = '/dashboard-owner'}

        >Initiate Tender</button>
      </div>
    </Container>
  );
};

export default EstimationProfile;
