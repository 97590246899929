const API_URL = "http://localhost:5005/api"; //Local Address
//const API_URL = "https://api.shotagent.com/api"; // Server Address

export { API_URL };

///File Location
const fileBaseUrl = "http://localhost:5005/uploads"; // Change this to your server's base URL
//const fileBaseUrl = "https://api.shotagent.com/uploads"; // Server Address

export { fileBaseUrl };
