import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { finishProject, fetchData, sendMessage } from '../api/freelancerPMApi';
import { fileBaseUrl } from '../api/constants';


import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";

import img1 from '../assets/images/icon/check.png';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function FreelancerProjectManagement(props) {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');
    console.log('projectId:', projectId);
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [project, setProject] = useState([]);
    const [notification, setNotification] = useState('');


    useEffect(() => {
        const fetchConversationData = async () => {
            try {
                const backData = await fetchData(projectId);
                const conversationData = backData.conversations;
                console.log('conversations from back', backData.conversations)
                setConversations(conversationData[0]);
                const projectData = backData.projects;
                setProject(projectData[0][0]);
                console.log('project from back', backData.projects[0][0])
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        };
        fetchConversationData();
    }, [projectId]);

    const handleFileChange = (event) => {
        setFiles([...event.target.files]);
    };

    console.log('previouse conversations:', conversations);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setNotification('Sending message...');

            // Update conversations state immediately
            setConversations(prevConversations => [...prevConversations, { message }]);

            const formData = new FormData();
            formData.append('projectId', projectId);
            formData.append('clientEmail', project.client_email); // Replace with actual freelancer email
            formData.append('clientFirstName', project.client_first_name); // Replace with actual freelancer first name
            formData.append('clientLastName', project.client_last_name); // Replace with actual freelancer last name
            formData.append('freelancerEmail', project.freelancer_email); // Replace with actual freelancer email
            formData.append('freelancerFirstName', project.freelancer_first_name); // Replace with actual freelancer first name
            formData.append('freelancerLastName', project.freelancer_last_name); // Replace with actual freelancer last name
            formData.append('message', message);
            console.log('client email:', project.client_email)
            // Append each file to FormData
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append(`files`, files[i]);
                }
            }

            await sendMessage(formData);
            setNotification('Message sent successfully!');

            // Clear input fields after sending message
            setMessage('');
            setFiles([]);
            // Reload the page after a short delay
            setTimeout(() => {
                window.location.reload();
            }, 1000); // 
        } catch (error) {
            console.error('Error sending message:', error);
            setNotification('Error sending message. Please try again.');
        } finally {
            // Clear notification after a short delay
            setTimeout(() => {
                setNotification('');
            }, 2000); // 2000 milliseconds (2 seconds)
        }
    };

    const handleFinishProject = async () => {
        try {
            await finishProject(projectId);
            // Optionally, you can handle success actions here, such as displaying a message or updating state
            // Show success notification
            toast.success('The project was completed successfully and moved to the archive!', {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = '/dashboard-freelancer';
            }, 4000);
        } catch (error) {
            console.error('Error finishing project:', error);
            // Optionally, you can handle error actions here, such as displaying an error message
            toast.error('Project completion failed, try again!', {
                // Toast configuration for other registration errors
            });
        }
    };


    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Project-Management' />
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post">
                                <div className="inner-content">
                                    <h2 className="title">{project.title}</h2>
                                    
                                    <div className="sc-card-article">
                                        <div className="meta-info style">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={require('../assets/images/avatar/avt-01.png')} alt="Nufti" />
                                                </div>
                                                <div className="info">
                                                    <h3> {project.client_first_name} {project.client_last_name} </h3>
                                                    <div className="date"> Date <span> {project.creation_date}</span></div>
                                                </div>
                                            </div>
                                            <button
                                                style={{
                                                    backgroundColor: 'rgb(239, 78, 123)',
                                                    color: 'white',
                                                    border: 'none',

                                                    cursor: 'pointer',
                                                    float: 'right', // Align to the right side
                                                }}
                                                onClick={handleFinishProject}>
                                                Finish Project
                                            </button>
                                        </div>
                                        <div className="back-arrow"
                                            style={{ cursor: "pointer", fontSize: "1.5em" }}
                                            onClick={() => navigate(-1)}
                                        >
                                            &#8592; Back
                                        </div>
                                    </div>
                                    <p className="text-boder">{project.project_description}</p>
                                </div>
                                <div id="comments">
                                    <ol className="comment-list">
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there is a message
                                            conversation.message && (
                                                <li
                                                    className="comment-01 flex mb-44"
                                                    key={conversation.id}
                                                    style={{
                                                        flexDirection: conversation.freelancer_email === conversation.sender_email ? 'row-reverse' : 'row',
                                                        textAlign: conversation.freelancer_email === conversation.sender_email ? 'right' : 'left'
                                                    }}
                                                >

                                                    <div className="comment-content"

                                                    >
                                                        <div className="comment-meta">
                                                            {/* Render freelancer's first name if freelancer sent the message, otherwise render client's first name */}
                                                            <div className="comment-author mb-18">
                                                                <h3>{conversation.freelancer_email === conversation.sender_email ? conversation.freelancer_first_name : conversation.client_first_name}</h3>
                                                                {/* Display the date of the message */}
                                                                <span className="message-date">{new Date(conversation.created_at).toLocaleDateString()}</span>
                                                            </div>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>{conversation.message}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                    </ol>
                                    {/* Render notification message */}
                                    <div id="respond" className="respond-comment">
                                        <div className="title-comment2 font-20-600">Send New Message</div>
                                        <form id="contactform" className="comment-form form-submit"
                                            action="#" method='post' acceptCharset="utf-8"
                                            onSubmit={handleSubmit}
                                        >
                                            <fieldset className="message-wrap">
                                                <textarea id="comment-message" name="message" rows="3" tabIndex="4"
                                                    placeholder="Additional Message"
                                                    value={message} onChange={(e) => setMessage(e.target.value)}
                                                ></textarea>
                                                <input type="file" onChange={handleFileChange} multiple />

                                            </fieldset>
                                            {notification && <div className="notification">{notification}</div>}
                                            <button name="submit" type="submit" id="comment-reply"
                                                className="button btn-submit-comment"><span>Send Message/File </span></button>
                                        </form>
                                    </div>

                                </div>

                            </article>

                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                                <div className="inner-side-bar">
                                    <div className="widget widget-categories style">
                                        <div className="widget-title title-list">
                                            Files
                                        </div>
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there are file URLs or messages
                                            (conversation.file_urls && conversation.file_urls.length > 0) && (
                                                <li className="comment-01 flex mb-44" key={conversation.id}>
                                                    <div className="comment-content">
                                                        <ul>
                                                            {/* Map through file URLs and render anchor elements */}
                                                            {conversation.file_urls.map((fileUrl, index) => {
                                                                // Check if fileUrl is not null or undefined
                                                                if (fileUrl) {
                                                                    // Extract the file name from the file URL
                                                                    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                                                                    const extractedFileName = fileName.split(/[\\/]/).pop();

                                                                    // Extract the creation date from the conversation object
                                                                    const creationDate = new Date(conversation.created_at).toLocaleDateString();

                                                                    // Replace backslashes with forward slashes and remove any leading dots
                                                                    const cleanRelativePath = fileUrl.split(/[\\/]/).pop();

                                                                    // Construct the complete download URL
                                                                    const fileDownloadUrl = fileBaseUrl + '/' + cleanRelativePath;

                                                                    // Display the file name and creation date
                                                                    return (
                                                                        <li key={index}>
                                                                            <a href={fileDownloadUrl} download>{extractedFileName} ({creationDate})</a>
                                                                        </li>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default FreelancerProjectManagement;
