import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProposalById, submitProposal } from '../api/projectProposalApi.js';

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Blog from '../components/blog/blog-v1/index.jsx';
import dataBlog from '../assets/fake-data/data-blog.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'
import EstimationProfile from '../components/layouts/requirement/estimationprofile.jsx';
import dataProfile from '../assets/fake-data/data-liveaution.js';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function RequirementsEstimation(props) {
    
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            {<EstimationProfile data={dataProfile}/>}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default RequirementsEstimation;