import React, { useState } from 'react';
import { sendMessage } from '../api/contactApi';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import { Link } from "react-router-dom";
import img1 from '../assets/images/icon/icon1.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact(props) {

    const [dataContact] = useState(
        [
            {
                img: img1,
                text: 'Contact Phone',
                adress: '+98 (444) 6765',
            },
            {
                img: img2,
                text: 'Contact Mail',
                adress: 'info@buildynamics.com',
            },
            {
                img: img3,
                text: 'Contact Location',
                adress: 'Tehran, Iran.',
            }
        ]
    )

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get form data
        const formData = {
            fullName: e.target.elements.fullName.value,
            company: e.target.elements.company.value,
            email: e.target.elements.email.value,
            phoneNumber: e.target.elements.phoneNumber.value,
            message: e.target.elements.message.value,
        };

        try {
            // Make POST request to backend
            const response = await sendMessage(formData);
            console.log('Message sent successfully', response);
            // Add code to handle success, e.g., show a success message to the user
            // Show success notification
            toast.success('Message sent successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = '/';
            }, 3000);

        } catch (error) {
            console.error('Error sending message', error);
            // Add code to handle error, e.g., show an error message to the user
            toast.error('The message was not sent!', {
                // Toast configuration for other Edit errors
            });
        }
    };

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Contact' />
            <section className="tf-section tf-map">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-adderss">
                                <div className="inner-box flex-one">
                                    {
                                        dataContact.map((item, index) => (
                                            <div className="post flex" key={index}>
                                                <div className="icon icon-phone">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <div className="content">
                                                    <p className="text-color-1">{item.text}</p>
                                                    <Link className="fw-4 fs-20" to="tel:+123456789">{item.adress}</Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className=" flat-contact flat-edit-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-contact">
                                <div className="heading center">
                                    <h2 className="fw-6">Contact Us</h2>
                                    <p>Feel free to contact us if you have any questions or need assistance.</p>
                                </div>
                                <div className="form-upload-profile post">
                                    <form action="#" className="form-profile" onSubmit={handleSubmit}>
                                        <div className="form-infor-profile">
                                            <div className="form-infor flex flat-form">
                                                <div className="info-account">
                                                    <fieldset>
                                                        <input type="text" name="fullName" placeholder="Your Full Name" required />
                                                    </fieldset>
                                                    <fieldset>
                                                        <input type="number" name="phoneNumber" placeholder="+55 (121) 234 444" required />
                                                    </fieldset>
                                                </div>
                                                <div className="info-social">
                                                    <fieldset>
                                                        <input type="email" name="email" placeholder="info.lawlead@gmail.com" required />
                                                    </fieldset>

                                                    <fieldset>
                                                        <input type="text" name="company" placeholder="Your Company Name" required />
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <fieldset className="message">
                                                <textarea placeholder="Additional Message" name="message" tabIndex="4" rows="3" required></textarea>
                                            </fieldset>
                                        </div>
                                        <div className="button-contact center">
                                            <button className="submit" type="submit">
                                                Submit Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default Contact;
