import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/profile-photo.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';
//import img2 from '../../.././assets/images/box-item/profile-cover.jpg';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fileBaseUrl } from '../../../api/constants.js';


const Profile1 = (props) => {
    useEffect(() => {
        // Check if the page has already been reloaded
        if (!sessionStorage.getItem('reloaded')) {
            // Reload the page
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        } else {
            // Clear the flag so that it doesn't persist
            sessionStorage.removeItem('reloaded');
        }
    }, []);
    const data = props.data;
    const [modalShow, setModalShow] = useState(false);


    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleEditClick = () => {
        navigate('/editProfile-freelancer');
    };

    return (
        <Fragment>
            <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                <div className="container">
                    <div className="flat-tabs tab-author">
                    <div className="author-profile flex" style={{ height: "15em", paddingTop: "0" }}>
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</h3>
                                    <div className="box-price flat-price">
                                        <div className="price flex">
                                            <div className="title-price text-color-1"> Your Name <br /><br />Partner Dashboard</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-profile">
                                <h3 className="text-color-1">Balance: <br />1000 Coins</h3>
                                <div
                                    className="edit-arrow"
                                    style={{ cursor: 'pointer', fontSize: '1.7em', marginTop: '2em', textDecoration: 'underline' }}
                                    onClick={handleEditClick}
                                >
                                    Edit Profile
                                </div>
                            </div>
                        </div>
                        <Tabs>
                            <TabList className="menu-tab tab-title" style={{ backgroundColor: 'Black', paddingLeft: "8em", paddingRight: "4em", paddingTop: "4em", paddingBottom: "4em" }}>
                                <Tab><h3>Bidding opportunities</h3></Tab>
                                <Tab><h3>Projects in Progress</h3></Tab>
                                <Tab><h3>Completed Projects & Auctions</h3></Tab>
                            </TabList>
                            <TabPanel>
                                {
                                    data.filter(item => item.status === 'start').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to={{ pathname: "/project-detail", search: `?id=${item.id}` }}>
                                                    <img src={item.img} alt="img" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <h3>
                                                    <Link to={{ pathname: "/project-detail", search: `?id=${item.id}` }}>
                                                        {item.title}
                                                    </Link>
                                                </h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> By <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Created On <span>{item.date}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">
                                                        <img src={item.icon2} alt="Nufti" />
                                                        <div className="title-price">{item.titleprice}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/project-detail", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btn}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>


                            <TabPanel>
                                {
                                    data.filter(item => item.freelancer_email === localStorage.getItem('email') && item.status === 'doing').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to={{ pathname: "/freelancer-project-management", search: `?id=${item.id}` }}>
                                                    <img src={item.img} alt="img" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <h3><Link to={{ pathname: "/freelancer-project-management", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={item.imgauthor} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> By <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> Starting on <span>{item.date}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">
                                                        <img src={item.icon2} alt="Nufti" />
                                                        <div className="title-price">{item.titleprice}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/freelancer-project-management", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"><span>{item.btn}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>

                            <TabPanel>
                                {
                                    data.filter(item => item.freelancer_email === localStorage.getItem('email') && item.status === 'done').slice(0, 10000).map((item, index) => (
                                        <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                            <div className="card-media flat-img">
                                                <Link to={{ pathname: "/freelancer-project-archive", search: `?id=${item.id}` }}>
                                                    <img src={item.img} alt="img" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <h3><Link to={{ pathname: "/freelancer-project-archive", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                <div className="meta-info mb-17">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={item.imgauthor} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            {item.author && (
                                                                <h5> By <h3 className="text-color-3" href="">{item.author}</h3> </h5>
                                                            )}
                                                            <div className="date"> In <span>{item.date}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">
                                                        <img src={item.icon2} alt="Nufti" />
                                                        <div className="title-price">{item.titleprice}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to={{ pathname: "/freelancer-project-archive", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3" ><span>{item.btn}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </div>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};


export default Profile1;
