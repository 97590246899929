import img3 from '../images/blog/blog-detail-1.jpg';

import imgavatar1 from '../images/avatar/avt-01.png';
import imgavatar2 from '../images/avatar/avt-02.png';
import imgavatar3 from '../images/avatar/avt-03.png';

const data = [
    {
        id: 1,
        img: img3,
        title: 'Faegheh Manafi',
        desc: 'She leads Buildynamic with visionary thinking and a passion for transforming construction financing through technology and innovation.',
        
        imgavatar: imgavatar1,
        author: 'Cofounder & CEO',
        date: '04/10/2022',
    },
    {
        id: 2,
        img: img3,
        title: 'Vahid Hoseini Niari',
        desc: 'He drives Buildynamic’s marketing strategies, combining expertise in branding and customer engagement to build a global presence.',
        imgavatar: imgavatar2,
        author: 'Cofounder & CMO',
        date: '04/10/2022',
    },
    {
        id: 3,
        img: img3,
        title: 'Seyedamirhossein Razavian',
        desc: 'He oversees the technical architecture, ensuring Buildynamic’s platforms are cutting-edge and scalable for future growth.',
        imgavatar: imgavatar3,
        author: 'Cofounder & CTO',
        date: '04/10/2022',
    },

    {
        id: 4,
        img: imgavatar1,
        title: 'Samereh Ghanbari',
        desc: 'She manages operations, focusing on efficiency and strategic partnerships to propel Buildynamic’s mission forward.',
        imgavatar: imgavatar3,
        author: 'Cofounder & COO',
        date: '04/10/2022',
    },


    {
        id: 5,
        img: img3,
        title: 'Royal House Project',
        desc: 'The issue centered around the timeline and quality of the delivered goods. The project owner claimed that the materials were delayed and did not meet the agreed specifications, which impacted the overall progress of the project.',
        imgavatar: imgavatar3,
        author: 'John Hiva',
        date: '04/10/2022',
    },

]

export default data;