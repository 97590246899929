import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/profile.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';
//import img2 from '../../.././assets/images/box-item/profile-cover.jpg';
import { Container, Dropdown } from "react-bootstrap";

import { fileBaseUrl } from '../../../api/constants.js';
//submit Request
//Bckend Connection
import { submitRequest } from "../../../api/requestApi";

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//components
import DownBut1 from "../../button/download1.js";

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);


const Profile1 = (props) => {
    useEffect(() => {
        // Check if the page has already been reloaded
        if (!sessionStorage.getItem('reloaded')) {
            // Reload the page
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        } else {
            // Clear the flag so that it doesn't persist
            sessionStorage.removeItem('reloaded');
        }
    }, []);
    const data = props.data;
    const [modalShow, setModalShow] = useState(false);

    //////////////// General Settings
    //Project Period List
    const [selectedPeriod, setSelectedPeriod] = useState("Click to Select One of the Items Listed");
    const [periodList] = useState(
        [
            {
                id: 1,
                title: "Short Term",
            },
            {
                id: 2,
                title: "Long Term",
            },
        ]
    )
    const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false);
    const handleItemClick = (title) => {
        setSelectedPeriod(title);
        setIsPeriodMenuOpen(false);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'collaborationPeriod', value: title } });
    };

    const handlePeriodMenuToggle = (isOpen) => {
        setIsPeriodMenuOpen(isOpen);
    };

    //Phase list
    const [selectedPhase, setSelectedPhase] = useState("Select Current Phase of the Project");
    const [phaseList] = useState(
        [
            {
                id: 1,
                title: "Conceptual Design",
            },
            {
                id: 2,
                title: "Feasibility Study",
            },
            {
                id: 3,
                title: "Pre-Design",
            },
            {
                id: 4,
                title: "Schematic Design",
            },
            {
                id: 5,
                title: "Design Development",
            },
            {
                id: 6,
                title: "Construction Documentation",
            },
            {
                id: 7,
                title: "Permitting and Approvals",
            },
            {
                id: 8,
                title: "Bidding and Procurement",
            },
            {
                id: 9,
                title: "Pre-Construction",
            },
            {
                id: 10,
                title: "Under Construction",
            },
            {
                id: 11,
                title: "Inspection and Quality Control",
            },
            {
                id: 12,
                title: "Substantial Completion",
            },

        ]
    )
    const handlePhaseClick = (title) => {
        setSelectedPhase(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'category', value: title } });
    };

    //Projcet Size list
    const [selectedSize, setSelectedSize] = useState("Click to Select One of the Items Listed");
    const [sizeList] = useState(
        [
            {
                id: 1,
                title: "Small",
            },
            {
                id: 2,
                title: "Medium",
            },
            {
                id: 3,
                title: "Large",
            },
        ]
    )
    const handleSizeClick = (title) => {
        setSelectedSize(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'size', value: title } });
    };

    //Projcet Experience list
    const [selectedType, setSelectedType] = useState("Select Type of the Building");
    const [typeList] = useState(
        [
            {
                id: 1,
                title: "Residential-Apartment",
            },
            {
                id: 2,
                title: "Residential-Villa",
            },
            {
                id: 3,
                title: "Commercial",
            },
            {
                id: 4,
                title: "Educational",
            },
            {
                id: 5,
                title: "Healthcare",
            },
            {
                id: 6,
                title: "Industrial",
            },
            {
                id: 7,
                title: "Recreational",
            },
            {
                id: 8,
                title: "Institutional",
            },
            {
                id: 9,
                title: "Hospitality",
            },
            {
                id: 10,
                title: "Retail",
            },
            {
                id: 11,
                title: "Cultural",
            },
            {
                id: 12,
                title: "Transportation",
            },
            {
                id: 13,
                title: "Agricultural",
            },
            {
                id: 14,
                title: "Public Safety",
            },

        ]
    )
    const handleTypeClick = (title) => {
        setSelectedType(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'buildingType', value: title } });
    };


    ///////////////////////Advanced Settings
    //Projcet Experience list
    const [selectedShotDuration, setSelectedShotDuration] = useState("Click to Select One of the Items Listed");
    const [shotDurationList] = useState(
        [
            {
                id: 1,
                title: "Ultra Short (less than 1 second)",
            },
            {
                id: 2,
                title: "Very Short (1-3 seconds)",
            },
        ]
    )
    const handleShotDurationClick = (title) => {
        setSelectedShotDuration(title);
        handleInputChange({ target: { name: 'shotDuration', value: title } });
    };


    //Projcet Light Conditions 
    const [selectedLight, setSelectedLight] = useState("Click to Select One of the Items Listed");
    const [lightList] = useState(
        [
            {
                id: 1,
                title: "Natural Sunlight",
            },
            {
                id: 2,
                title: "Studio Lighting",
            },

        ]
    )
    const handleLightClick = (title) => {
        setSelectedLight(title);
        handleInputChange({ target: { name: 'light', value: title } });
    };

    //Projcet Exposure Status 
    const [selectedTypeosure, setSelectedTypeosure] = useState("Click to Select One of the Items Listed");
    const [exposureList] = useState(
        [
            {
                id: 1,
                title: "Overexposed Highlights",
            },
            {
                id: 2,
                title: "Underexposed Shadows",
            },

        ]
    )
    const handleExposureClick = (title) => {
        setSelectedTypeosure(title);
        handleInputChange({ target: { name: 'exposure', value: title } });
    };

    //Projcet Image Depth 
    const [selectedImageDepth, setSelectedImageDepth] = useState("Click to Select One of the Items Listed");
    const [imageDepthList] = useState(
        [
            {
                id: 1,
                title: "Shallow Depth of Field (Portrait Mode)",
            },
            {
                id: 2,
                title: "Deep Depth of Field (Landscape Mode)",
            },

        ]
    )
    const handleImageDepthClick = (title) => {
        setSelectedImageDepth(title);
        handleInputChange({ target: { name: 'imageDepth', value: title } });
    };

    //Projcet Camera
    const [selectedCamera, setSelectedCamera] = useState("Click to Select One of the Items Listed");
    const [cameraList] = useState(
        [
            {
                id: 1,
                title: "Digital Single-Lens Reflex (DSLR)",
            },
            {
                id: 2,
                title: "Mirrorless",
            },
            {
                id: 3,
                title: "Camcorder",
            },

        ]
    )
    const handleCameraClick = (title) => {
        setSelectedCamera(title);
        handleInputChange({ target: { name: 'camera', value: title } });
    };

    //Projcet Lens
    const [selectedLens, setSelectedLens] = useState("Click to Select One of the Items Listed");
    const [lensList] = useState(
        [
            {
                id: 1,
                title: "Wide-Angle Prime",
            },
            {
                id: 2,
                title: "Standard Prime",
            },

        ]
    )
    const handleLensClick = (title) => {
        setSelectedLens(title);
        handleInputChange({ target: { name: 'lens', value: title } });
    };

    //Projcet Image Quality
    const [selectedQuality, setSelectedQuality] = useState("Click to Select One of the Items Listed");
    const [qualityList] = useState(
        [
            {
                id: 1,
                title: "Basic Resolution (e.g., 720p)",
            },
            {
                id: 2,
                title: "High Definition (HD - 1080p)",
            },

        ]
    )
    const handleQualityClick = (title) => {
        setSelectedQuality(title);
        handleInputChange({ target: { name: 'quality', value: title } });

    };


    //Projcet Inferior Colors
    const [selectedColor, setSelectedColor] = useState("Click to Select One of the Items Listed");
    const [colorList] = useState(
        [
            {
                id: 1,
                title: "Desaturated and Faded",
            },
            {
                id: 2,
                title: "Muted and Subdued",
            },

        ]
    )
    const handleColorClick = (title) => {
        setSelectedColor(title);
        handleInputChange({ target: { name: 'color', value: title } });
    };


    //Projcet Inferior Colors
    const [selectedInferiorColor, setSelectedInferiorColor] = useState("Click to Select One of the Items Listed");
    const [inferiorcolorList] = useState(
        [
            {
                id: 1,
                title: "Desaturated and Faded",
            },
            {
                id: 2,
                title: "Muted and Subdued",
            },

        ]
    )
    const handleInferiorColorClick = (title) => {
        setSelectedInferiorColor(title);
        handleInputChange({ target: { name: 'inferiorcolor', value: title } });
    };

    //State of Tilt Movement Status
    const [selectedTiltMovement, setSelectedTiltMovement] = useState("Click to Select One of the Items Listed");
    const [tiltMovementList] = useState(
        [
            {
                id: 1,
                title: "Gentle Upward Tilt",
            },
            {
                id: 2,
                title: "Steep Upward Tilt",
            },

        ]
    )
    const handleTiltMovementClick = (title) => {
        setSelectedTiltMovement(title);
        handleInputChange({ target: { name: 'tiltMovement', value: title } });
    };

    //State of Pan Movement Status
    const [selectedPanMovement, setSelectedPanMovement] = useState("Click to Select One of the Items Listed");
    const [panMovementList] = useState(
        [
            {
                id: 1,
                title: "Slow Pan Left",
            },
            {
                id: 2,
                title: "Slow Pan Right",
            },

        ]
    )
    const handlePanMovementClick = (title) => {
        setSelectedPanMovement(title);
        handleInputChange({ target: { name: 'panMovement', value: title } });
    };

    //State of Crane Movement Status
    const [selectedCraneMovement, setSelectedCraneMovement] = useState("Click to Select One of the Items Listed");
    const [craneMovementList] = useState(
        [
            {
                id: 1,
                title: "Vertical Crane Up",
            },
            {
                id: 2,
                title: "Vertical Crane Down",
            },

        ]
    )
    const handleCraneMovementClick = (title) => {
        setSelectedCraneMovement(title);
        handleInputChange({ target: { name: 'craneMovement', value: title } });
    };


    //State of Dolly Movement Status
    const [selectedDollyMovement, setSelectedDollyMovement] = useState("Click to Select One of the Items Listed");
    const [dollyMovementList] = useState(
        [
            {
                id: 1,
                title: "Dolly In",
            },
            {
                id: 2,
                title: "Dolly Out",
            },

        ]
    )
    const handleDollyMovementClick = (title) => {
        setSelectedDollyMovement(title);
        handleInputChange({ target: { name: 'dollyMovement', value: title } });
    };

    //State of Aerial Shot Movement Status
    const [selectedAerialShot, setSelectedAerialShot] = useState("Click to Select One of the Items Listed");
    const [aerialShotList] = useState(
        [
            {
                id: 1,
                title: "Hovering (Stationary Aerial Shot)",
            },
            {
                id: 2,
                title: "Ascending Vertically",
            },

        ]
    )
    const handleAerialShotClick = (title) => {
        setSelectedAerialShot(title);
        handleInputChange({ target: { name: 'aerialShot', value: title } });
    };



    //State of Objects in the View 
    const [selectedObjectsInView, setSelectedObjectsInView] = useState("Click to Select One of the Items Listed");
    const [objectsInViewList] = useState(
        [
            {
                id: 1,
                title: "Natural Landscapes",
            },
            {
                id: 2,
                title: "Urban Landscapes",
            },

        ]
    )
    const handleObjectsInViewClick = (title) => {
        setSelectedObjectsInView(title);
        handleInputChange({ target: { name: 'objectsInView', value: title } });
    };



    //Description of Each Object in the View  
    const [selectedDescriptionOfObject, setSelectedDescriptionOfObject] = useState("Click to Select One of the Items Listed");
    const [descriptionOfObjectList] = useState(
        [
            {
                id: 1,
                title: "Specific Object Details",
            },
            {
                id: 2,
                title: "Individual's Appearance and Attire",
            },

        ]
    )
    const handleDescriptionOfObjectClick = (title) => {
        setSelectedDescriptionOfObject(title);
        handleInputChange({ target: { name: 'descriptionOfObject', value: title } });
    };

    ///Permutation and Ratio of the Part to the Whole of Each Object in Each View 
    const [selectedObjectRatio, setSelectedObjectRatio] = useState("Click to Select One of the Items Listed");
    const [objectRatioList] = useState(
        [
            {
                id: 1,
                title: "Close-Up Details (e.g., extreme close-up)",
            },
            {
                id: 2,
                title: "Half and Half (50/50)",
            },

        ]
    )
    const handleObjectRatioClick = (title) => {
        setSelectedObjectRatio(title);
        handleInputChange({ target: { name: 'objectRatio', value: title } });
    };

    ///Number of Possible Actors  
    const [selectedNumberOfActors, setSelectedNumberOfActors] = useState("Click to Select One of the Items Listed");
    const [numberOfActorsList] = useState(
        [
            {
                id: 1,
                title: "Solo Performer",
            },
            {
                id: 2,
                title: "Duo or Pair",
            },

        ]
    )
    const handleNumberOfActorsClick = (title) => {
        setSelectedNumberOfActors(title);
        handleInputChange({ target: { name: 'numberOfActors', value: title } });
    };

    ///Acts of Each Actor   
    const [selectedActsOfActor, setSelectedActsOfActor] = useState("Click to Select One of the Items Listed");
    const [actsOfActorList] = useState(
        [
            {
                id: 1,
                title: "Dialogue and Conversation",
            },
            {
                id: 2,
                title: "Monologue or Soliloquy",
            },

        ]
    )
    const handleActsOfActorClick = (title) => {
        setSelectedActsOfActor(title);
        handleInputChange({ target: { name: 'actsOfActor', value: title } });
    };

    ///Expected Innovative Technique    
    const [selectedInnovation, setSelectedInnovation] = useState("Click to Select One of the Items Listed");
    const [innovationList] = useState(
        [
            {
                id: 1,
                title: "Time-Remapping (Time-Warp Effect)",
            },
            {
                id: 2,
                title: "Hyperlapse with Motion Control",
            },

        ]
    )
    const handleInnovationClick = (title) => {
        setSelectedInnovation(title);
        handleInputChange({ target: { name: 'innovation', value: title } });
    };



    ///Add Storyboard    
    const [selectedStoryboard, setSelectedStoryboard] = useState("Click to Select One of the Items Listed");
    const [storyboardList] = useState(
        [
            {
                id: 1,
                title: "Detailed Shot-By-Shot Storyboard",
            },
            {
                id: 2,
                title: "Rough Sketch Storyboard",
            },

        ]
    )
    const handleStoryboardClick = (title) => {
        setSelectedStoryboard(title);
        handleInputChange({ target: { name: 'storyboard', value: title } });
    };


    ///Output Format 
    const [selectedOutputFormat, setSelectedOutputFormat] = useState("Click to Select One of the Items Listed");
    const [outPutFormatList] = useState(
        [
            {
                id: 1,
                title: "MP4",
            },
            {
                id: 2,
                title: "AVI",
            },

        ]
    )
    const handleOutputFormatClick = (title) => {
        setSelectedOutputFormat(title);
        handleInputChange({ target: { name: 'outputFormat', value: title } });
    };

    ///Output Format 
    const [selectedFrameSize, setSelectedFrameSize] = useState("Click to Select One of the Items Listed");
    const [frameSizeList] = useState(
        [
            {
                id: 1,
                title: "640x480 (VGA)",
            },
            {
                id: 2,
                title: "720x480 (SD)",
            },
            {
                id: 3,
                title: "1280x720 (HD)",
            },

        ]
    )
    const handleFrameSizeClick = (title) => {
        setSelectedFrameSize(title);
        handleInputChange({ target: { name: 'frameSize', value: title } });
    };



    ///Submit Request:
    const stored_email = localStorage.getItem('email');
    const stored_first_name = localStorage.getItem('firstName');
    const stored_last_name = localStorage.getItem('lastName');

    const [requestData, setRequestData] = useState({
        clientEmail: stored_email,
        clientFirstName: stored_first_name,
        clientLastName: stored_last_name,
        freelancerEmail: '',
        freelancerFirstName: '',
        freelancerLastName: '',
        collaborationPeriod: '',
        title: '',
        category: '',
        size: '',
        buildingType: '',
        budget: 0,
        outputFormat: '',
        totalArea: '',
        landArea: '',
        floorArea: '',
        numberOfFloors: 1,
        unitsPerFloor: 1,
        totalUnits: 1,
        frameSize: '',
        projectTags: '',
        projectDescription: '',
        rfpLink: '',
        shotDuration: '',
        light: '',
        exposure: '',
        imageDepth: '',
        camera: '',
        lens: '',
        quality: '',
        color: '',
        inferiorcolor: '',
        tiltMovement: '',
        panMovement: '',
        craneMovement: '',
        dollyMovement: '',
        aerialShot: '',
        objectsInView: '',
        descriptionOfObject: '',
        objectRatio: '',
        numberOfActors: '',
        actsOfActor: '',
        innovation: '',
        storyboard: ''
    });

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleRequest = async () => {
        try {
            const response = await submitRequest(requestData);
            // Handle the response, e.g., show a success message, redirect, etc.
            console.log('Submission successful', response);
            setRequestSuccess(true); // Set the registration success state to true

            // Show success notification
            toast.success('Submission successful: Check "Current Requests" Tab', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = '/dashboard-owner';
            }, 4000);
        } catch (error) {
            // Handle registration error, e.g., display an error message
            console.error('Submission error', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;

                if (errorMessage === 'Invalid email format') {
                    toast.error('Please enter a valid email address!', {
                        // Toast configuration for invalid email format error
                    });
                }
            } else {
                toast.error('Submission failed!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };


    const handleInputChange = (e) => {
        // Update the userData state when input fields change
        setRequestData({ ...requestData, [e.target.name]: e.target.value });
        //console.log('InputChange:',requestData )
    };


    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSettings = () => {
        setIsExpanded(!isExpanded);
    };

    const advButtonStyle = {
        padding: '10px 20px', // Adjust padding for desired size
        backgroundColor: 'rgb(241, 77, 128)', // Set background color using RGB values
        color: '#ffffff', // Set text color
        border: 'none', // Remove border
        borderRadius: '5px', // Add border radius for rounded corners
        cursor: 'pointer', // Change cursor to pointer on hover
    };
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleEditClick = () => {
        navigate('/editProfile-owner');
    };



    return (
        <Fragment>

            <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex" style={{ height: "15em", paddingTop: "0" }}>
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{localStorage.getItem('firstName')} {localStorage.getItem('lastName')}</h3>
                                    <div className="box-price flat-price">
                                        <div className="price flex">
                                            <div className="title-price text-color-1"> Your Name <br /><br />Owner Dashboard</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-profile">
                                <h3 className="text-color-1">Balance: <br />1000 Coins</h3>
                                <div
                                    className="edit-arrow"
                                    style={{ cursor: 'pointer', fontSize: '1.7em', marginTop: '2em', textDecoration: 'underline' }}
                                    onClick={handleEditClick}
                                >
                                    Edit Profile
                                </div>
                            </div>
                        </div>
                        <Tabs >
                            <TabList className="menu-tab " style={{ backgroundColor: 'Black', paddingLeft: "8em", paddingRight: "4em", paddingTop: "4em", paddingBottom: "4em" }}>
                                <Tab><h3>Create New Project</h3></Tab>
                                <Tab><h3>Pre-Tender Projects</h3></Tab>
                                <Tab><h3>Tendering Projects</h3></Tab>
                                <Tab><h3>Projects in Progress</h3></Tab>
                                <Tab><h3>Completed Projects & Auctions</h3></Tab>
                            </TabList>
                            <TabPanel>
                                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}

                                {
                                    <div className="tf-section flat-explore flat-edit-profile flat-auctions-details ">
                                        <div className="container">
                                            <div className="form-upload-profile post">
                                                <form action="#" className="form-profile" style={{ position: 'relative' }}>
                                                    <div className="form-infor-profile">
                                                        <h3 className="title-one fw-6">General Sepcs (Must be Filled)</h3>
                                                        <div className="form-infor flex flat-form"  >
                                                            <div className="info-account" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em' }}>
                                                                    <p className="title-infor-account">Project Name</p>
                                                                    <input type="text" placeholder="Enter Your Project Name" required name="title"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Land Area </p>
                                                                    <input type="number" placeholder="Enter Total Plot Area (in m^2)" required name="landArea"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Number of Floors </p>
                                                                    <input type="number" placeholder="Enter Number of floors" required name="numberOfFloors"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Project Description</p>
                                                                    <textarea tabIndex="4" rows="3" required name="projectDescription"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>



                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                    <p className="title-infor-account">Building Type</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown
                                                                            style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedType}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {typeList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handleTypeClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">Total Built-Up Area</p>
                                                                        <input type="number" placeholder="Enter Cumulative area of all floors in the building (in m^2)" name="totalArea" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Number of Units per Floor</p>
                                                                    <input type="number" placeholder="Enter Number of Units per Floor" name="unitsPerFloor" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>

                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '0.9em', zIndex: 1 }}>
                                                                    <p className="title-infor-account">Current Phase of the Project</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown style={{ zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedPhase}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {phaseList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handlePhaseClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Floor Area</p>
                                                                    <input type="number" placeholder="Enter Area per Floor (in m^2)" name="floorArea" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Total Number of Units</p>
                                                                    <input type="number" placeholder="Enter Total number of units" name="totalUnits" style={{ maxHeight: "2.6em" }} required
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    />
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                        <h3 className="title-one fw-6" style={{ marginTop: "2em" }}>Project Location Details</h3>
                                                        <div className="form-infor flex flat-form"  >
                                                            <div className="info-account" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em' }}>
                                                                    <p className="title-infor-account">Country</p>
                                                                    <div id="item-create1" className="dropdown info-title" >
                                                                        <Dropdown
                                                                            style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                                            >
                                                                                {selectedType}
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                                {typeList.map(data => (
                                                                                    <Dropdown.Item key={data.id} onClick={() => handleTypeClick(data.title)}
                                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                                    >{data.title}</Dropdown.Item>
                                                                                ))}
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                    <p className="title-infor-account">Neighborhood/Locality </p>
                                                                    <input type="text" placeholder="Enter Total Plot Area (in m^2)" required name="landArea"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                                    />
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Project Address</p>
                                                                    <textarea tabIndex="4" rows="3" required name="projectDescription"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>
                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 1 }}>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">State/Province/Region</p>
                                                                        <input type="text" placeholder="Enter Cumulative area of all floors in the building (in m^2)" name="totalArea" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">Postal/ZIP Code</p>
                                                                        <input type="number" placeholder="Enter Cumulative area of all floors in the building (in m^2)" name="totalArea" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Map Link</p>
                                                                    <textarea tabIndex="4" rows="3" required name="projectDescription"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>

                                                            </div>
                                                            <div className="info-social" style={{ width: '30em' }}>
                                                                <fieldset style={{ marginBottom: '0.9em', zIndex: 1 }}>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">City</p>
                                                                        <input type="number" placeholder="Enter Cumulative area of all floors in the building (in m^2)" name="totalArea" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>
                                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                                        <p className="title-infor-account">Plot Number</p>
                                                                        <input type="number" placeholder="Enter Cumulative area of all floors in the building (in m^2)" name="totalArea" style={{ maxHeight: "2.6em" }} required
                                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                                        />
                                                                    </fieldset>

                                                                </fieldset>
                                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                                    <p className="title-infor-account">Site Accessibility Information </p>
                                                                    <textarea tabIndex="4" rows="3" required name="projectDescription"
                                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                                    ></textarea>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                        <h3 className="title-one fw-6" style={{ marginTop: "2em" }} >Upload Maps</h3>

                                                        <div style={{ marginBottom: "4em", marginTop: "2em", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <div style={{ display: "flex", justifyContent: "center", gap: "2em" }}> {/* Flex container for buttons */}
                                                                <div style={{ textAlign: "center" }}>
                                                                    <DownBut1 />
                                                                    <p>Plan Upload</p>
                                                                </div>
                                                                <div style={{ textAlign: "center" }}>
                                                                    <DownBut1 />
                                                                    <p>3D Upload</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <button className="tf-button-submit mg-t-15" style={{ zIndex: 0 }}
                                                        onClick={e => {
                                                            e.preventDefault(); // Prevent the default form submission behavior
                                                            handleRequest();
                                                        }}>
                                                        Send Your Request
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </TabPanel>
                            {/*2nd Tab - Pre-Tender */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Check your created projects</h3>
                                    <p style={{ marginTop: "1em" }}>Check the requirements of your created projects and put them out to tender to find the best partners.</p>
                                </Container>
                                {
                                    data
                                        .filter(item => item.status === 'start1') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h3><Link to={{ pathname: "/requirements", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> Created On <span>{item.date}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                    >

                                                        <div className="button-place-bid"

                                                        >
                                                            <Link to={{ pathname: "/requirements", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn_pre}</span></Link>
                                                        </div>
                                                    </div>
                                                    <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                        <Link
                                                            to={{ pathname: "/owner-project-detail", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 1em',  // Add padding for better spacing
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.reviewbtn_pre}</span>
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>

                                        ))
                                }

                            </TabPanel>
                            {/*3rd Tab - Tendering */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Project Name: XYZ Building</h3>
                                </Container>
                                {
                                    data
                                        .filter(item => item.status === 'tendering1') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.tags}</h6>
                                                    <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> Estimated Coins <span>{item.titleprice_tend}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn}</span></Link>
                                                        </div>
                                                    </div>
                                                    <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                        <Link
                                                            to={{ pathname: "/owner-project-detail", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 1em',  // Add padding for better spacing
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.reviewbtn}</span>
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>

                                        ))
                                }
                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Project Name: ABC Building <br /></h3>
                                    {
                                        data
                                            .filter(item => item.status === 'tendering2') // Filter items by status
                                            .slice(0, 10000)
                                            .map((item, index) => (
                                                <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                    <div className="content">
                                                        <h6 className="tags">{item.tags}</h6>
                                                        <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                        <div className="meta-info mb-17">
                                                            <div className="author">
                                                                <div className="info">
                                                                    <div className="date"> Etimated Coins <span>{item.titleprice_tend}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            <div className="button-place-bid">
                                                                <Link to={{ pathname: "/project-applicants", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        maxHeight: "3em",  // Adjust the height as needed
                                                                        height: '3em',     // Ensure the button has enough height to center the text
                                                                        padding: '0 2em',  // Add padding for better spacing
                                                                        width: '21em',
                                                                        maxWidth: '21em',
                                                                        textAlign: 'center'
                                                                    }}
                                                                ><span>{item.btn}</span></Link>
                                                            </div>
                                                        </div>
                                                        <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                            <Link
                                                                to={{ pathname: "/owner-project-detail", search: `?id=${item.id}` }}
                                                                data-toggle="modal"
                                                                data-target="#popup_bid"
                                                                className="sc-button btn-2"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 1em',  // Add padding for better spacing
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <span>{item.reviewbtn}</span>
                                                            </Link>
                                                        </div>


                                                    </div>
                                                </div>

                                            ))
                                    }
                                </Container>

                            </TabPanel>

                            {/*4th Tab - in Progress */}
                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Project Name: XYZ Building</h3>

                                </Container>
                                {
                                    data
                                        .filter(item => item.status === 'doing11') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.tags}</h6>
                                                    <h3 style={{ marginTop: "2em" }}>
                                                        <Link to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }}>{item.title}</Link>
                                                    </h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date">Until the deadline
                                                                    {/* Custom Horizontal Progress Bar */}
                                                                    <div className="progress-bar-container" style={{ marginTop: "0em" }}>
                                                                        <div
                                                                            className="progress-bar"
                                                                            style={{
                                                                                width: 90, // Adjust width based on progress `${item.progress}%`
                                                                                backgroundColor: '#36A2EB', // Color of the progress bar
                                                                                height: '1em', // Height of the progress bar
                                                                                borderRadius: '5px', // Rounded corners
                                                                                transition: 'width 0.5s ease-in-out' // Smooth transition effect
                                                                            }}
                                                                        >
                                                                            <span className="progress-text" style={{ color: 'white', fontWeight: 'bold' }}>{`${item.progress}%`}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="button-place-bid">
                                                        <Link
                                                            to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-3"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 2em',  // Add padding for better spacing
                                                                width: '21em',
                                                                maxWidth: '21em',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.btn_progress}</span>
                                                        </Link>
                                                    </div>




                                                </div>
                                            </div>
                                        ))
                                }


                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Project Name: ABC Building <br /></h3>
                                    {
                                        data
                                            .filter(item => item.status === 'doing12') // Filter items by status
                                            .slice(0, 10000)
                                            .map((item, index) => (
                                                <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                    <div className="content">
                                                        <h6 className="tags">{item.tags}</h6>
                                                        <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                        <div className="meta-info mb-17">
                                                            <div className="author">
                                                                <div className="info">
                                                                    <div className="date">Until the deadline
                                                                        {/* Custom Horizontal Progress Bar */}
                                                                        <div className="progress-bar-container" style={{ marginTop: "0em" }}>
                                                                            <div
                                                                                className="progress-bar"
                                                                                style={{
                                                                                    width: 90, // Adjust width based on progress `${item.progress}%`
                                                                                    backgroundColor: '#36A2EB', // Color of the progress bar
                                                                                    height: '1em', // Height of the progress bar
                                                                                    borderRadius: '5px', // Rounded corners
                                                                                    transition: 'width 0.5s ease-in-out' // Smooth transition effect
                                                                                }}
                                                                            >
                                                                                <span className="progress-text" style={{ color: 'white', fontWeight: 'bold' }}>{`${item.progress}%`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            <div className="button-place-bid">
                                                                <Link to={{ pathname: "/owner-project-management", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        maxHeight: "3em",  // Adjust the height as needed
                                                                        height: '3em',     // Ensure the button has enough height to center the text
                                                                        padding: '0 2em',  // Add padding for better spacing
                                                                        width: '21em',
                                                                        maxWidth: '21em',
                                                                        textAlign: 'center'
                                                                    }}
                                                                ><span>{item.btn}</span></Link>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>

                                            ))
                                    }
                                </Container>

                            </TabPanel>


                            {/*5th Tab - Completed&Auctions */}

                            <TabPanel>
                                <Container style={{ marginBottom: "2em" }}>
                                    <h3>Projects Under Auction</h3>
                                </Container>
                                {
                                    data
                                        .filter(item => item.status === 'done1') // Filter items by status
                                        .slice(0, 10000)
                                        .map((item, index) => (
                                            <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                <div className="content">
                                                    <h6 className="tags">{item.tags_done1}</h6>
                                                    <h3 style={{ marginTop: "2em" }}><Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">
                                                            <div className="info">
                                                                <div className="date"> Your Coins <span>{item.titleprice_done1}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <div className="button-place-bid">
                                                            <Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 2em',  // Add padding for better spacing
                                                                    width: '21em',
                                                                    maxWidth: '21em',
                                                                    textAlign: 'center'
                                                                }}
                                                            ><span>{item.btn_done1}</span></Link>
                                                        </div>
                                                    </div>
                                                    <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                        <Link
                                                            to={{ pathname: "/dashboard-owner", search: `?id=${item.id}` }}
                                                            data-toggle="modal"
                                                            data-target="#popup_bid"
                                                            className="sc-button btn-2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxHeight: "3em",  // Adjust the height as needed
                                                                height: '3em',     // Ensure the button has enough height to center the text
                                                                padding: '0 1em',  // Add padding for better spacing
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <span>{item.reviewbtn_done1}</span>
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>

                                        ))
                                }
                                <Container>
                                    <hr style={{ margin: '20px 0', borderColor: '#ccc' }} />
                                    <h3 style={{ marginTop: "1em", marginBottom: "2em" }}>Sold Projects <br /></h3>
                                    {
                                        data
                                            .filter(item => item.status === 'done2') // Filter items by status
                                            .slice(0, 10000)
                                            .map((item, index) => (
                                                <div className="sc-card-product sc-card-article blog-article" key={item.id}>
                                                    <div className="content">
                                                        <h3 style={{ marginTop: "0.1em" }}><Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }}>{item.title}</Link></h3>
                                                        <div className="meta-info mb-17">
                                                            <div className="author">
                                                                <div className="info">
                                                                    <div className="date"> Project Price <span>{item.titleprice}</span></div>
                                                                </div>
                                                                <div className="info">
                                                                    <div className="date"> Your Share <span>{item.share}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            <div className="button-place-bid">
                                                                <Link to={{ pathname: "/set-auction", search: `?id=${item.id}` }} data-toggle="modal" data-target="#popup_bid" className="sc-button btn-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        maxHeight: "3em",  // Adjust the height as needed
                                                                        height: '3em',     // Ensure the button has enough height to center the text
                                                                        padding: '0 2em',  // Add padding for better spacing
                                                                        width: '21em',
                                                                        maxWidth: '21em',
                                                                        textAlign: 'center'
                                                                    }}
                                                                ><span>{item.btn_done2}</span></Link>
                                                            </div>
                                                        </div>
                                                        <div className="button-place-bid" style={{ marginTop: "2em" }}>
                                                            <Link
                                                                to={{ pathname: "/dashboard-owner", search: `?id=${item.id}` }}
                                                                data-toggle="modal"
                                                                data-target="#popup_bid"
                                                                className="sc-button btn-2"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    maxHeight: "3em",  // Adjust the height as needed
                                                                    height: '3em',     // Ensure the button has enough height to center the text
                                                                    padding: '0 1em',  // Add padding for better spacing
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <span>{item.reviewbtn_done2}</span>
                                                            </Link>
                                                        </div>


                                                    </div>
                                                </div>

                                            ))
                                    }
                                </Container>
                            </TabPanel>
                        </Tabs>
                    </div >
                </div >
            </div >
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment >
    );
};


export default Profile1;
