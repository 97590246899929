// my-app/src/api/projectDatailApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchProjectById = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-detail?id=${projectId}`);
        console.log('fetched project detail from backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitProposal = async (proposalData) => {
    try {
        await axios.post(`${API_URL}/project-detail?id=${proposalData.projectId}`, proposalData);
        console.log('post proposal to backend:', proposalData);
        // Optionally, handle success cases
    } catch (error) {
        throw error;
    }
};