// my-app/src/api/requestApi.js
import axios from 'axios';

//---------------------1 changes for deploy
import { API_URL } from './constants';

export const submitRequest = async (requestData) => {
  try {
    console.log("requestData in api:", requestData)
    const response = await axios.post(`${API_URL}/dashboard-client`, requestData);
    console.log("backend response:", response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
};

