import React from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material'; // Social media icons from MUI
import { useNavigate } from 'react-router-dom';

// Sample images (Replace with actual image paths)
import heroImage from '../../assets/images/hero-bg.jpg';
import Grid2 from "@mui/material/Grid2"; // Use Grid2 for MUI v5+

const NewHome = () => {
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/contact');
    };

    return (
        <div>
            {/* Hero Section */}
            <div style={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}>
                <Container sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // White with opacity for glass effect
                    backdropFilter: 'blur(2px)', // Apply blur for frosted glass
                    padding: '2em', // Add padding for spacing
                    borderRadius: '15px', // Rounded corners
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Optional: Add a soft shadow for depth
                    border: '1px solid rgba(255, 255, 255, 0.3)', // Optional: Add a subtle border
                    textAlign: 'center', // Center the text
                    width: "80em",
                }}>
                    <Typography variant="h2" sx={{ fontWeight: 'bold', color: "navy" }}>
                        Revolutionizing Real Estate with Technology
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: "navy", mt: 2 }}>
                        Leverage the power of blockchain, automation, and smart contracts <br />
                        to redefine construction and real estate investment.
                    </Typography>
                    <div className="sc-btn-top" id="site-header">
                    <Button addclass='header-slider style wallet fl-button pri-1' sx={{ mt: 4 }}>
                        Discover Our Solutions
                    </Button>
                    </div>
                </Container>

            </div>
        </div>
    );
};

export default NewHome;
