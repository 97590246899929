import React from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';

const images = [
    {
        src: require('../../../assets/images/mark/b1.jpg'),
        fullWidth: true,
        height: '0em' // Adjust the height for this image
    },
    /*
    {
        src: require('../../../assets/images/mark/cam-circle - title.png'),
        fullWidth: true,
        height: '300px' // Adjust the height for this image
    },
    */

 
    /*
    {
        src: require('../../../assets/images/mark/video-camera_8063451.png'),
        fullWidth: true,
        height: '350px' // Adjust the height for this image
    },
    */
    {
        src: require('../../../assets/images/mark/mk-header-055.jpg'),
        fullWidth: true,
        height: '0em' // Adjust the height for this image
    }
];

PageTitle.propTypes = {
    title: PropTypes.string,
};

function PageTitle(props) {
    const { title } = props;

    return (
        <section className="flat-title-page inner">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.src}
                    alt="Nufti"
                    className={`absolute mark-page${index + 1} ${image.fullWidth ? 'full-width' : ''}`}
                    style={{ height: image.height }}
                />
            ))}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/*
                        <div className="page-title-heading">
                            <h1 className="heading">{title}<span className="text-color-3">.</span></h1>
                        </div>  
                        */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageTitle;
