// my-app/src/api/clientDashboardApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchRequests = async (clientEmail) => {
    try {
        console.log('clientEmail in API', clientEmail);
        const response = await axios.get(`${API_URL}/dashboard-client`, {
            params: {
                clientEmail: clientEmail
            }
        });
        console.log('Fetched your requests from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
