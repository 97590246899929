import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { finishProject, fetchData, sendMessage } from '../api/freelancerArchiveApi';
import { fileBaseUrl } from '../api/constants';


import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";

import img1 from '../assets/images/icon/check.png';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function FreelancerProjectManagement(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');
    console.log('projectId:', projectId);
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [project, setProject] = useState([]);
    const [notification, setNotification] = useState('');


    useEffect(() => {
        const fetchConversationData = async () => {
            try {
                const backData = await fetchData(projectId);
                const conversationData = backData.conversations;
                console.log('conversations from back', backData.conversations)
                setConversations(conversationData[0]);
                const projectData = backData.projects;
                setProject(projectData[0][0]);
                console.log('project from back', backData.projects[0][0])
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        };
        fetchConversationData();
    }, [projectId]);



    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Project-Management' />
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post">
                                <div className="inner-content">
                                    <h2 className="title">{project.title}</h2>
                                    <div className="sc-card-article">
                                        <div className="meta-info style">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={require('../assets/images/avatar/avt-01.png')} alt="Nufti" />
                                                </div>
                                                <div className="info">
                                                    <h5> By<Link className="text-color-3" to="/"> {project.client_first_name} {project.client_last_name}</Link> </h5>
                                                    <div className="date"> Date <span> {project.creation_date}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-boder">{project.project_description}</p>
                                </div>
                                <div id="comments">
                                <ol className="comment-list">
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there is a message
                                            conversation.message && (
                                                <li
                                                    className="comment-01 flex mb-44"
                                                    key={conversation.id}
                                                    style={{
                                                        flexDirection: conversation.freelancer_email === conversation.sender_email ? 'row-reverse' : 'row',
                                                        textAlign: conversation.freelancer_email === conversation.sender_email ? 'right' : 'left'
                                                    }}
                                                >

                                                    <div className="comment-content"

                                                    >
                                                        <div className="comment-meta">
                                                            {/* Render freelancer's first name if freelancer sent the message, otherwise render client's first name */}
                                                            <div className="comment-author mb-18">
                                                                <h3>{conversation.freelancer_email === conversation.sender_email ? conversation.freelancer_first_name : conversation.client_first_name}</h3>
                                                                {/* Display the date of the message */}
                                                                <span className="message-date">{new Date(conversation.created_at).toLocaleDateString()}</span>
                                                            </div>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>{conversation.message}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                    </ol>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                                <div className="inner-side-bar">
                                <div className="widget widget-categories style">
                                        <div className="widget-title title-list">
                                            Files
                                        </div>
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there are file URLs or messages
                                            (conversation.file_urls && conversation.file_urls.length > 0) && (
                                                <li className="comment-01 flex mb-44" key={conversation.id}>
                                                    <div className="comment-content">
                                                        <ul>
                                                            {/* Map through file URLs and render anchor elements */}
                                                            {conversation.file_urls.map((fileUrl, index) => {
                                                                // Check if fileUrl is not null or undefined
                                                                if (fileUrl) {
                                                                    // Extract the file name from the file URL
                                                                    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                                                                    const extractedFileName = fileName.split(/[\\/]/).pop();

                                                                    // Extract the creation date from the conversation object
                                                                    const creationDate = new Date(conversation.created_at).toLocaleDateString();

                                                                    // Replace backslashes with forward slashes and remove any leading dots
                                                                    const cleanRelativePath = fileUrl.split(/[\\/]/).pop();

                                                                    // Construct the complete download URL
                                                                    const fileDownloadUrl = fileBaseUrl + '/' + cleanRelativePath;

                                                                    // Display the file name and creation date
                                                                    return (
                                                                        <li key={index}>
                                                                            <a href={fileDownloadUrl} download>{extractedFileName} ({creationDate})</a>
                                                                        </li>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default FreelancerProjectManagement;