import img1 from '../images/box-item/auctions-10.jpg';
import img2 from '../images/box-item/auctions-10.jpg';
import img3 from '../images/box-item/auctions-10.jpg';
import img4 from '../images/box-item/auctions-10.jpg';

import img5 from '../images/box-item/building.jpg';
import img6 from '../images/box-item/auctions-10.jpg';
import img7 from '../images/box-item/auctions-10.jpg';
import img8 from '../images/box-item/auctions-10.jpg';
import img9 from '../images/box-item/auctions-8.jpg';
import img10 from '../images/box-item/auctions-9.jpg';
import img11 from '../images/box-item/auctions-10.jpg';
import img12 from '../images/box-item/auctions-11.jpg';


import imgauthor1 from '../images/avatar/avt-01.png';

import icon1 from '../images/icon/author-check.svg';
import icon2 from '../images/icon/icon-diamond.svg';


const dataLiveaution = [
    {
        id: 1,
        img: img1,
        tags: '6 New Freelancers',
        title: 'Rose Building',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '03/10/2024',
        titleprice: '350 EUR',
        btn: 'Place Bid',
        icon1 : icon1,
        icon2 : icon2,
        titlecountdown: 'Ending Time -',
    },
    {
        id: 2,
        img: img2,
        tags: '2 New Freelancers',
        title: "Crazy House",
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '20000 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 3,
        img: img3,
        tags: '7 New Freelancers',
        title: 'X Project',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '5300000 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 4,
        img: img4,
        tags: '73 New Freelancers',
        title: 'Royal Complex',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },

    {
        id: 5,
        img: img5,
        tags: '11 New Freelancers',
        title: 'Building XY',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '9200000 EUR',
        btn: 'Place Bid',
        icon1 : icon1,
        icon2 : icon2,
        titlecountdown: 'Ending Time -',
    },
    {
        id: 6,
        img: img6,
        tags: '12 New Freelancers',
        title: 'Building XYZ',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 7,
        img: img7,
        tags: '2 New Freelancers',
        title: 'Building XYA',
        imgauthor: imgauthor1,
        author: 'Themesflat',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 8,
        img: img8,
        tags: '0 New Freelancers',
        title: 'Building XYC',
        imgauthor: imgauthor1,
        author: 'Brian Shakiri',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 9,
        img: img9,
        tags: '1 New Messages',
        title: 'Building XYP',
        imgauthor: imgauthor1,
        author: 'David Kaufman',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },

    {
        id: 10,
        img: img10,
        tags: 'Building XYPP',
        title: 'Blue Eyes',
        imgauthor: imgauthor1,
        author: 'Sarah Samson',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        icon1 : icon1,
        icon2 : icon2,
        titlecountdown: 'Ending Time -',
    },
    {
        id: 11,
        img: img9,
        tags: '6 New Messages',
        title: 'Building XYQ',
        imgauthor: imgauthor1,
        author: 'George Kaya',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 12,
        img: img10,
        tags: 'Building XYQQ',
        title: 'Toy room',
        imgauthor: imgauthor1,
        author: 'Yogi and friends',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 13,
        img: img11,
        tags: '0 New Messages',
        title: 'Building XYI',
        imgauthor: imgauthor1,
        author: 'Hell Team',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        icon1 : icon1,
        icon2 : icon2,
        titlecountdown: 'Ending Time -',
    },
    {
        id: 14,
        img: img12,
        tags: '2 New Messages',
        title: 'knockback',
        imgauthor: imgauthor1,
        author: 'Group of stars',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 15,
        img: img7,
        tags: '6 New Messages',
        title: 'star explosion',
        imgauthor: imgauthor1,
        author: 'Jonathan Rasouli',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 16,
        img: img8,
        tags: '3 New Messages',
        title: 'Desert partridges',
        imgauthor: imgauthor1,
        author: 'Kimia Zare',
        date: '01/01/2024',
        titleprice: '2.39 EUR',
        btn: 'Place Bid',
        titlecountdown: 'Ending Time -',
        icon1 : icon1,
        icon2 : icon2,
    },

]

export default dataLiveaution;