// my-app/src/api/projectDatailApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchProposalById = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-proposal?id=${projectId}`);
        console.log('fetched proposal detail from backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const AcceptFreelancer = async (proposalData) => {
    try {
        await axios.post(`${API_URL}/project-proposal?id=${proposalData.projectId}`, proposalData);
        console.log('post freelancers to backend:', proposalData);
        // Optionally, handle success cases
    } catch (error) {
        throw error;
    }
};